import { AllActions } from "app/actions";
import { GlobalFeedEditorDialogTypes } from "app/actions/types";
import { IContext } from "app/modules/editor/container/baseContext";
import produce from "immer";

export interface IState extends IContext {
  open: boolean;
}

export const INITIAL_STATE: IState = {
  open: false,
};

export function reducer(state = INITIAL_STATE, action: AllActions) {
  return produce(state, draft => {
    switch (action.type) {
      case GlobalFeedEditorDialogTypes.OPEN: {
        draft.open = true;
        draft.fromChannelId =
          state.open && !action.payload.fromChannelId
            ? state.fromChannelId
            : action.payload.fromChannelId;
        draft.fromThreadId = action.payload.fromThreadId;
        draft.fromDraftId = action.payload.fromDraftId;
        draft.blockPageEdit = action.payload.blockPageEdit;
        draft.afterSave = action.payload.afterSave;
        break;
      }
      case GlobalFeedEditorDialogTypes.CLOSE: {
        return {
          ...INITIAL_STATE,
        };
      }
    }
  });
}
