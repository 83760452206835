import { IAppState } from "app/rootReducer";
import { createSelector } from "reselect";
import GroupInputTypes from "./type";

export const selectImgBlocks = createSelector(
  (state: IAppState, ids: { id: Moim.Id; priority: number }[]) =>
    ids
      .filter(i => Boolean(i))
      .map(i => state.entities.files[i.id] as Moim.Upload.IFileInfo),
  fileEntities =>
    fileEntities.map(fileEntity => {
      if (fileEntity) {
        if (fileEntity?.image_preview && fileEntity?.image_urls) {
          return {
            type: "image",
            status: fileEntity.status,
            fileId: fileEntity.id,
            ...fileEntity.image_preview,
            ...fileEntity.image_urls,
          } as GroupInputTypes.FileStatusImageBlock;
        }
        return {
          type: "image",
          status: fileEntity.status,
          fileId: fileEntity.id,
        };
      }
      return undefined;
    }) as GroupInputTypes.FileStatusImageBlock[],
);

export const selectVideoBlocks = createSelector(
  (state: IAppState, ids: { id: Moim.Id; priority: number }[]) =>
    ids
      .filter(i => Boolean(i))
      .map(i => state.entities.files[i.id] as Moim.Upload.IFileInfo),
  fileEntities =>
    fileEntities.map(fileEntity => {
      if (fileEntity) {
        if (fileEntity?.image_preview && fileEntity?.image_urls) {
          return {
            type: "video",
            status: fileEntity.status,
            fileId: fileEntity.id,
            fileName: fileEntity.name,
            mimeType: fileEntity.mimetype,
            metaData: {
              width: fileEntity.image_preview.width,
              height: fileEntity.image_preview.height,
              poster: fileEntity.image_preview.url,
              ratio: fileEntity.image_preview.ratio,
            },
            sourceUrl: fileEntity.url_private,
          } as GroupInputTypes.FileStatusVideoBlock;
        }
        return {
          type: "video",
          fileId: fileEntity.id,
          fileName: fileEntity.name,
          mimeType: fileEntity.mimetype,
        };
      }

      return undefined;
    }) as GroupInputTypes.FileStatusVideoBlock[],
);
