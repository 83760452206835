import * as React from "react";
import ImageHolder from "common/components/lazyBlurHashImage";
import { SkeletonBox } from "common/components/skeleton";
import { px2rem } from "common/helpers/rem";
import {
  SIZE,
  Wrapper,
  RemoveButtonContainer,
  SmallRemoveButton,
  ImageWrapperStyle,
  ImageStyle,
} from "./styled";

interface IProps {
  id: Moim.Id;
  payload?: Moim.Blockit.ImageProps;
  isLoading?: boolean;
  imageScale?: string;
  skeletonSize?: string;
  CustomRemoveButton?: React.FC<{ onClick(id: Moim.Id): void }>;
  onRemoveClick?(id: Moim.Id): void;
}

const ImageUploadThumbnail: React.FC<IProps> = ({
  id,
  payload,
  isLoading,
  imageScale,
  skeletonSize,
  CustomRemoveButton,
  onRemoveClick,
}) => {
  const handleRemoveClick = React.useCallback(() => {
    onRemoveClick?.(id);
  }, [id, onRemoveClick]);

  return (
    <Wrapper>
      {isLoading || !payload ? (
        <SkeletonBox
          width={skeletonSize ?? px2rem(SIZE)}
          height={skeletonSize ?? px2rem(SIZE)}
        />
      ) : (
        <ImageHolder
          src={payload[imageScale!] ?? payload.src}
          srcSet={!imageScale ? payload.srcSet : undefined}
          blurHash={payload.blur_hash}
          fallBackSrc={payload.fallbackSrc ?? payload.src}
          width={payload.width}
          height={payload.height}
          overrideWrapperStyle={ImageWrapperStyle}
          overrideIMGStyle={ImageStyle}
        />
      )}
      {onRemoveClick &&
        (CustomRemoveButton ? (
          <CustomRemoveButton onClick={handleRemoveClick} />
        ) : (
          <RemoveButtonContainer onClick={handleRemoveClick}>
            <SmallRemoveButton />
          </RemoveButtonContainer>
        ))}
    </Wrapper>
  );
};

export default ImageUploadThumbnail;
