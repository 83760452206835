import {
  ExpandIcon,
  ChangeStatusButton,
} from "app/modules/navigationPanel/container/joinedSubMoims/styled";
import { NavigationPanelContext } from "app/modules/navigationPanel/context";
import { useStoreState } from "app/store";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useCurrentHubGroup from "common/hooks/useCurrentHubGroup";
import useCurrentUser from "common/hooks/useCurrentUser";
import useHover from "common/hooks/useHover";
import useIsTablet from "common/hooks/useIsTablet";
import { TopNavigationContext } from "../context";
import useIsCurrentGroupVisibleInTopNavi from "../hooks/useIsCurrentGroupVisible";
import ElementRenderer from "./components/elementRenderer";
import { Wrapper, InnerWrapper, JoinedSubMoimOpenButton } from "./styled";

const LOCAL_CACHE_KEY = "cl_top_navi_web";

export default function DesktopTopNavigation() {
  const [hoverRef, hover] = useHover<HTMLDivElement>();
  const {
    visibleMoimName,
    setVisibleMoimName,
    onlyMoreMenu,
    setVisibleOnlyMoreMenu,
    visibleSimpSearch,
    setVisibleSimpSearch,
  } = React.useContext(TopNavigationContext);
  const { joinedSubMoimsStatus, setJoinedSubMoimsStatus } = React.useContext(
    NavigationPanelContext,
  );

  const isTablet = useIsTablet();
  const isCurrentGroupVisible = useIsCurrentGroupVisibleInTopNavi();
  const currentUser = useCurrentUser();
  const parentGroup = useCurrentHubGroup();
  const currentGroup = useCurrentGroup();
  const { joinedMoimCount } = useStoreState(storeState => ({
    joinedMoimCount: storeState.subgroupsData.joinedSubMoims.data.length,
  }));

  const cachedCLElement = JSON.parse(
    localStorage.getItem(LOCAL_CACHE_KEY) ?? "[]",
  );

  const apiResponseElement = useStoreState(state => {
    return state.componentLayout.layout[
      currentGroup?.is_hub ||
      currentGroup?.navigation_config?.isIndependentChild
        ? `${currentGroup?.id ?? ""}|top-navigation|web`
        : `${parentGroup?.id ?? ""}|top-navigation-for-child|web`
    ]?.blocks;
  });

  const visibleGroup = React.useMemo(() => {
    if (isCurrentGroupVisible) {
      return currentGroup as Moim.Group.INormalizedGroup | null;
    } else {
      return parentGroup;
    }
  }, [parentGroup, currentGroup, isCurrentGroupVisible]);

  const handleResize = React.useCallback(
    (width: number) => {
      if (width < 650 && !onlyMoreMenu) {
        setVisibleOnlyMoreMenu(true);
      }

      if (width > 650 && onlyMoreMenu) {
        setVisibleOnlyMoreMenu(false);
      }

      if (width < 700 && !visibleSimpSearch) {
        setVisibleSimpSearch(true);
      }

      if (width > 700 && visibleSimpSearch) {
        setVisibleSimpSearch(false);
      }

      if (width < 620 && visibleMoimName) {
        setVisibleMoimName(false);
      }

      if (width > 620 && !visibleMoimName) {
        setVisibleMoimName(true);
      }
    },
    [
      visibleMoimName,
      setVisibleMoimName,
      onlyMoreMenu,
      setVisibleOnlyMoreMenu,
      visibleSimpSearch,
      setVisibleSimpSearch,
    ],
  );

  const { ref } = useResizeDetector({
    handleWidth: true,
    refreshMode: "debounce",
    onResize: handleResize,
  });

  const handleOpenJoinedSubMoims = React.useCallback(() => {
    setJoinedSubMoimsStatus("Open");
  }, [setJoinedSubMoimsStatus]);

  const joinedSubMoimOpenButtonElement = React.useMemo(() => {
    if (
      (!hover && !isTablet) ||
      !visibleGroup?.sub_groups_count ||
      !currentUser ||
      (Boolean(currentGroup?.navigation_config?.showTopTabOnWeb) &&
        !joinedMoimCount)
    ) {
      return;
    }

    switch (joinedSubMoimsStatus) {
      case "Disabled": {
        return (
          <JoinedSubMoimOpenButton>
            <ChangeStatusButton onClick={handleOpenJoinedSubMoims}>
              <ExpandIcon />
            </ChangeStatusButton>
          </JoinedSubMoimOpenButton>
        );
      }
      default:
        return null;
    }
  }, [
    joinedMoimCount,
    currentGroup?.navigation_config?.showTopTabOnWeb,
    currentUser,
    handleOpenJoinedSubMoims,
    hover,
    isTablet,
    joinedSubMoimsStatus,
    visibleGroup,
  ]);

  const topNaviElements = apiResponseElement ?? cachedCLElement;

  const innerElement = React.useMemo(
    () =>
      topNaviElements?.map((element, index) => (
        <ElementRenderer
          key={`${element.type}-${index}`}
          element={element}
          zIndex={index}
        />
      )),
    [topNaviElements],
  );

  React.useEffect(() => {
    if (Boolean(apiResponseElement?.length)) {
      localStorage.setItem(LOCAL_CACHE_KEY, JSON.stringify(apiResponseElement));
    }
  }, [apiResponseElement]);

  return (
    <Wrapper ref={hoverRef} isMultiRow={topNaviElements?.length > 1}>
      {joinedSubMoimOpenButtonElement}
      <InnerWrapper ref={ref} padding={topNaviElements[0]?.padding}>
        {innerElement}
      </InnerWrapper>
    </Wrapper>
  );
}
