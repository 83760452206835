import { ActionUnion } from "app/actions/helpers";
import { GlobalFeedEditorDialogTypes } from "app/actions/types";
import { IContext } from "app/modules/editor/container/baseContext";

function createAction<T extends { type: GlobalFeedEditorDialogTypes }>(
  d: T,
): T {
  return d;
}

export const ActionCreators = {
  open: (payload: IContext) =>
    createAction({
      type: GlobalFeedEditorDialogTypes.OPEN,
      payload,
    }),

  close: () =>
    createAction({
      type: GlobalFeedEditorDialogTypes.CLOSE,
    }),
};

export type Actions = ActionUnion<typeof ActionCreators>;
