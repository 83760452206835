/* eslint-disable no-shadow */
export enum GlobalDialogTypes {
  OPEN_USER_VERIFICATION_DIALOG = "VINGLE_DIALOG.OPEN_USER_VERIFICATION_DIALOG",
  CLOSE_USER_VERIFICATION_DIALOG = "VINGLE_DIALOG.CLOSE_USER_VERIFICATION_DIALOG",

  OPEN_SIGN_IN_DIALOG = "VINGLE_DIALOG.OPEN_SIGN_IN_DIALOG",
  CLOSE_SIGN_IN_DIALOG = "VINGLE_DIALOG.CLOSE_SIGN_IN_DIALOG",

  OPEN_MOBILE_APP_DOWNLOAD_DIALOG = "VINGLE_DIALOG.OPEN_MOBILE_APP_DOWNLOAD_DIALOG",
  CLOSE_MOBILE_APP_DOWNLOAD_DIALOG = "VINGLE_DIALOG.CLOSE_MOBILE_APP_DOWNLOAD_DIALOG",

  OPEN_SIGN_UP_DIALOG = "VINGLE_DIALOG.OPEN_SIGN_UP_DIALOG",
  CLOSE_SIGN_UP_DIALOG = "VINGLE_DIALOG.CLOSE_SIGN_UP_DIALOG",

  OPEN_EXTERNAL_SHARE_DIALOG = "VINGLE_DIALOG.OPEN_EXTERNAL_SHARE_DIALOG",
  CLOSE_EXTERNAL_SHARE_DIALOG = "VINGLE_DIALOG.CLOSE_EXTERNAL_SHARE_DIALOG",

  OPEN_REPORT_POST_DIALOG = "OPEN_REPORT_POST_DIALOG",
  CLOSE_REPORT_POST_DIALOG = "CLOSE_REPORT_POST_DIALOG",
}

export enum MyTalksShowTypes {
  TALK_CLICKED = "MY_TALKS.TALK_CLICKED",
}

export enum NavbarTypes {
  SET_DEFAULT = "NAVBAR.SET_DEFAULT",
  SET_FIXED = "NAVBAR.SET_FIXED",
  SET_STICKY_APPEAR = "NAVBAR.SET_STICKY_APPEAR",
  SET_STICKY_DISAPPEAR = "NAVBAR.SET_STICKY_DISAPPEAR",
  SET_TOP = "NAVBAR.SET_TOP",
}

export enum ChannelTypes {
  START_FETCHING_CHANNEL = "CHANNEL.START_FETCHING_CHANNEL",

  START_FETCHING_CHANNEL_LIST = "CHANNEL.START_FETCHING_CHANNEL_LIST",
  SUCCEEDED_FETCHING_CHANNEL_LIST = "CHANNEL.SUCCEEDED_FETCHING_CHANNEL_LIST",
  FAILED_FETCHING_CHANNEL_LIST = "CHANNEL.FAILED_FETCHING_CHANNEL_LIST",

  START_CREATE_CHANNEL = "CHANNEL.START_CREATE_CHANNEL",
  SUCCEEDED_CREATE_CHANNEL = "CHANNEL.SUCCEEDED_CREATE_CHANNEL",
  FAILED_CREATE_CHANNEL = "CHANNEL.FAILED_CREATE_CHANNEL",

  START_DELETE_CHANNEL = "CHANNEL.START_DELETE_CHANNEL",
  SUCCEEDED_DELETE_CHANNEL = "CHANNEL.SUCCEEDED_DELETE_CHANNEL",
  FAILED_DELETE_CHANNEL = "CHANNEL.FAILED_DELETE_CHANNEL",

  START_EDIT_CHANNEL = "CHANNEL.START_EDIT_CHANNEL",
  SUCCEEDED_EDIT_CHANNEL = "CHANNEL.SUCCEEDED_EDIT_CHANNEL",
  FAILED_EDIT_CHANNEL = "CHANNEL.FAILED_EDIT_CHANNEL",

  START_GET_LINK_CHANNEL_LIST = "CHANNEL.START_GET_LINK_CHANNEL_LIST",
  SUCCEEDED_GET_LINK_CHANNEL_LIST = "CHANNEL.SUCCEEDED_GET_LINK_CHANNEL_LIST",
  FAILED_GET_LINK_CHANNEL_LIST = "CHANNEL.FAILED_GET_LINK_CHANNEL_LIST",

  START_CREATE_LINK_CHANNEL = "CHANNEL.START_CREATE_LINK_CHANNEL",
  SUCCEEDED_CREATE_LINK_CHANNEL = "CHANNEL.SUCCEEDED_CREATE_LINK_CHANNEL",
  FAILED_CREATE_LINK_CHANNEL = "CHANNEL.FAILED_CREATE_LINK_CHANNEL",

  START_EDIT_LINK_CHANNEL = "CHANNEL.START_EDIT_LINK_CHANNEL",
  SUCCEEDED_EDIT_LINK_CHANNEL = "CHANNEL.SUCCEEDED_EDIT_LINK_CHANNEL",
  FAILED_EDIT_LINK_CHANNEL = "CHANNEL.FAILED_EDIT_LINK_CHANNEL",

  START_UPDATE_CHANNEL_STAT = "CHANNEL.START_UPDATE_CHANNEL_STAT",
  FAILED_UPDATE_CHANNEL_STAT = "CHANNEL.FAILED_UPDATE_CHANNEL_STAT",
}

export enum GroupTypes {
  SUCCEEDED_GET_INITIAL_GROUP_ID = "GROUP.SUCCEEDED_GET_INITIAL_GROUP_ID",

  START_BOOT_STRAP = "GROUP.START_BOOT_STRAP",
  SUCCESS_BOOT_STRAP = "GROUP.SUCCESS_BOOT_STRAP",
  FAILED_BOOT_STRAP = "GROUP.FAILED_BOOT_STRAP",

  START_VALIDATE_GROUP_DOMAIN = "GROUP.START_VALIDATE_GROUP_DOMAIN",
  VALIDATE_GROUP_DOMAIN = "GROUP.VALIDATE_GROUP_DOMAIN",

  START_FETCHING_LIST_GROUP = "GROUP.START_FETCHING_LIST_GROUP",
  FETCHED_LIST_GROUP = "GROUP.FETCHED_LIST_GROUP",
  FAILED_FETCHING_LIST_GROUP = "GROUP.FAILED_FETCHING_LIST_GROUP",

  START_UPDATE_GROUP_ICON = "GROUP.START_UPDATE_GROUP_ICON",
  SUCCEED_UPDATE_GROUP_ICON = "GROUP.SUCCEED_UPDATE_GROUP_ICON",
  FAILED_UPDATE_GROUP_ICON = "GROUP.FAILED_UPDATE_GROUP_ICON",

  START_CROP_GROUP_ICON = "GROUP.START_CROP_GROUP_ICON",
  SUCCEEDED_CROP_GROUP_ICON = "GROUP.SUCCEEDED_CROP_GROUP_ICON",
  FAILED_CROP_GROUP_ICON = "GROUP.FAILED_CROP_GROUP_ICON",

  START_CROP_GROUP_BANNER = "GROUP.START_CROP_GROUP_BANNER",
  SUCCEEDED_CROP_GROUP_BANNER = "GROUP.SUCCEEDED_CROP_GROUP_BANNER",
  FAILED_CROP_GROUP_BANNER = "GROUP.FAILED_CROP_GROUP_BANNER",

  START_UPDATE_GROUP_BANNER = "GROUP.START_UPDATE_GROUP_BANNER",
  SUCCEEDED_UPDATE_GROUP_BANNER = "GROUP.SUCCEEDED_UPDATE_GROUP_BANNER",
  FAILED_UPDATE_GROUP_BANNER = "GROUP.FAILED_UPDATE_GROUP_BANNER",

  START_CREATE_GROUP = "GROUP.START_CREATE_GROUP",
  SUCCEEDED_CREATE_GROUP = "GROUP.SUCCEEDED_CREATE_GROUP",
  FAILED_CREATE_GROUP = "GROUP.FAILED_CREATE_GROUP",

  START_CREATE_SUB_GROUP = "GROUP.START_CREATE_SUB_GROUP",
  SUCCEEDED_CREATE_SUB_GROUP = "GROUP.SUCCEEDED_CREATE_SUB_GROUP",
  FAILED_CREATE_SUB_GROUP = "GROUP.FAILED_CREATE_SUB_GROUP",

  START_GET_GROUP_DATA = "GROUP.START_GET_GROUP_DATA",
  SUCCEEDED_GET_GROUP_DATA = "GROUP.SUCCEEDED_GET_GROUP_DATA",
  FAILED_GET_GROUP_DATA = "GROUP.FAILED_GET_GROUP_DATA",

  START_FETCHING_GROUP_STATUS = "GROUP.START_FETCHING_GROUP_STATUS",
  FETCHED_GROUP_STATUS = "GROUP.FETCHED_GROUP_STATUS",
  FAILED_FETCHING_GROUP_STATUS = "GROUP.FAILED_FETCHING_GROUP_STATUS",

  START_FETCHING_SUB_MOIMS = "GROUP.START_FETCHING_SUB_MOIMS",
  FETCHED_SUB_MOIMS = "GROUP.FETCHED_SUB_MOIMS",
  FAILED_FETCHING_SUB_MOIMS = "GROUP.FAILED_FETCHING_SUB_MOIMS",

  START_GET_JOINED_SUB_MOIMS = "GROUP.START_GET_JOINED_SUB_MOIMS",
  SUCCEED_GET_JOINED_SUB_MOIMS = "GROUP.SUCCEED_FETCHED_JOINED_SUB_MOIMS",
  FAILED_GET_JOINED_SUB_MOIMS = "GROUP.FAILED_GET_JOINED_SUB_MOIMS",

  OPEN_JOIN_GROUP_DIALOG = "GROUP.OPEN_JOIN_GROUP_DIALOG",
  CLOSE_JOIN_GROUP_DIALOG = "GROUP.CLOSE_JOIN_GROUP_DIALOG",

  CLEAR_MOIM_ICON = "GROUP.CLEAR_MOIM_ICON",

  START_RENAME_MOIM = "GROUP.START_RENAME_MOIM",
  SUCCEED_RENAME_MOIM = "GROUP.SUCCEED_RENAME_MOIM",
  FAILED_RENAME_MOIM = "GROUP.FAILED_RENAME_MOIM",

  START_SET_DESCRIPTION_MOIM = "GROUP.START_SET_DESCRIPTION_MOIM",
  SUCCEED_SET_DESCRIPTION_MOIM = "GROUP.SUCCEED_SET_DESCRIPTION_MOIM",
  FAILED_SET_DESCRIPTION_MOIM = "GROUP.FAILED_SET_DESCRIPTION_MOIM",

  START_GET_MOIM_THEME = "GROUP.START_GET_MOIM_THEME",
  SUCCEED_GET_MOIM_THEME = "GROUP.SUCCEED_GET_MOIM_THEME",
  FAILED_GET_MOIM_THEME = "GROUP.FAILED_GET_MOIM_THEME",

  START_GET_MOIM_COVER = "GROUP.START_GET_MOIM_COVER",
  SUCCEED_GET_MOIM_COVER = "GROUP.SUCCEED_GET_MOIM_COVER",
  FAILED_GET_MOIM_COVER = "GROUP.FAILED_GET_MOIM_COVER",

  START_UPDATE_HOME_CHANNEL = "GROUP.START_UPDATE_DEFAULT_CHANNEL",
  SUCCEED_UPDATE_HOME_CHANNEL = "GROUP.SUCCEED_UPDATE_DEFAULT_CHANNEL",
  FAILED_UPDATE_HOME_CHANNEL = "GROUP.FAILED_UPDATE_DEFAULT_CHANNEL",

  START_GET_RECOMMEND_MOIMS = "GROUP.START_GET_RECOMMEND_MOIMS",
  SUCCEED_GET_RECOMMEND_MOIMS = "GROUP.SUCCEED_GET_RECOMMEND_MOIMS",
  FAILED_GET_RECOMMEND_MOIMS = "GROUP.FAILED_GET_RECOMMEND_MOIMS",

  START_SEARCH_MOIMS = "GROUP.START_SEARCH_MOIMS",
  SUCCEED_SEARCH_MOIMS = "GROUP.SUCCEED_SEARCH_MOIMS",
  FAILED_SEARCH_MOIMS = "GROUP.FAILED_SEARCH_MOIMS",
  CLEAR_SEARCH_MOIMS = "GROUP.CLEAR_SEARCH_MOIMS",

  START_GET_INSTALLED_PLUGINS = "GROUP.START_GET_INSTALLED_PLUGINS",
  SUCCEED_GET_INSTALLED_PLUGINS = "GROUP.SUCCEED_GET_INSTALLED_PLUGINS",
  FAILED_GET_INSTALLED_PLUGINS = "GROUP.FAILED_GET_INSTALLED_PLUGINS",
}

export enum SocketTypes {
  CONNECT = "REDUX_WEBSOCKET::CONNECT",
  DISCONNECT = "REDUX_WEBSOCKET::DISCONNECT",
  ON = "REDUX_WEBSOCKET::MESSAGE",
  EMIT = "REDUX_WEBSOCKET::SEND",
}

export enum ConfirmEmailTypes {
  START_SENDING_CONFIRM_EMAIL = "CONFIRM_EMAIL.START_SENDING_CONFIRM_EMAIL",
  FINISHED_SENDING_CONFIRM_EMAIL = "CONFIRM_EMAIL.FINISHED_SENDING_CONFIRM_EMAIL",
  FAILED_SENDING_CONFIRM_EMAIL = "CONFIRM_EMAIL.FAILED_SENDING_CONFIRM_EMAIL",
}

export enum ResetPasswordTypes {
  START_SENDING_EMAIL = "RESET_PASSWORD.START_SENDING_EMAIL",
  FINISHED_SENDING_EMAIL = "RESET_PASSWORD.FINISHED_SENDING_EMAIL",
  FAILED_SENDING_EMAIL = "RESET_PASSWORD.FAILED_SENDING_EMAIL",

  START_RESET_PASSWORD = "RESET_PASSWORD.START_RESET_PASSWORD",
  SUCCEEDED_RESET_PASSWORD = "RESET_PASSWORD.SUCCEEDED_RESET_PASSWORD",
  FAILED_RESET_PASSWORD = "RESET_PASSWORD.FAILED_RESET_PASSWORD",
}

export enum HubAppStateTypes {
  START_FETCHING_MY_GROUP = "HUB_APP.START_FETCHING_MY_GROUP",
  SUCCEEDED_FETCHING_MY_GROUP = "HUB_APP.SUCCEEDED_FETCHING_MY_GROUP",
  FAILED_FETCHING_MY_GROUP = "HUB_APP.FAILED_FETCHING_MY_GROUP",

  START_JOIN_GROUP = "HUB_APP.START_JOIN_GROUP",
  SUCCEEDED_JOIN_GROUP = "HUB_APP.SUCCEEDED_JOIN_GROUP",
  FAILED_JOIN_GROUP = "HUB_APP.FAILED_JOIN_GROUP",

  START_FILE_UPLOAD = "HUB_APP.START_FILE_UPLOAD",
  SUCCEEDED_FILE_UPLOAD = "HUB_APP.SUCCEEDED_FILE_UPLOAD",
  FAILED_FILE_UPLOAD = "HUB_APP.FAILED_FILE_UPLOAD",
}

export enum UserTypes {
  START_SEARCH_BY_KEYWORD = "USER.START_SEARCH_BY_KEYWORD",
  SUCCEED_SEARCH_BY_KEYWORD = "USER.SUCCEED_SEARCH_BY_KEYWORD",
  FAILED_SEARCH_BY_KEYWORD = "USER.FAILED_SEARCH_BY_KEYWORD",

  START_GET_USERS = "USER.START_GET_USERS",
  SUCCEED_GET_USERS = "USER.SUCCEED_GET_USERS",
  FAILED_GET_USERS = "USER.FAILED_GET_USERS",

  CLEAR_SEARCHED_USERS = "USER.CLEAR_SEARCHED_USERS",

  START_GET_SEARCH_USERS = "USER.START_GET_SEARCH_USERS",
  SUCCEED_GET_SEARCH_USERS = "USER.SUCCEED_GET_SEARCH_USERS",
  FAILED_GET_SEARCH_USERS = "USER.FAILED_GET_SEARCH_USERS",

  START_POST_USER = "USER.START_POST_USER",
  SUCCEED_POST_USER = "USER.SUCCEED_POST_USER",
  FAILED_POST_USER = "USER.FAILED_POST_USER",

  START_GET_BATCH_USERS = "USER.START_GET_BATCH_USERS",
  SUCCEED_GET_BATCH_USERS = "USER.SUCCEED_GET_BATCH_USERS",
  FAILED_GET_BATCH_USERS = "USER.FAILED_GET_BATCH_USERS",

  START_GET_PARENT_MOIM_USER_DATA = "USER.START_GET_PARENT_MOIM_USER_DATA",
  SUCCEEDED_GET_PARENT_MOIM_USER_DATA = "USER.SUCCEEDED_GET_PARENT_MOIM_USER_DATA",
  FAILED_GET_PARENT_MOIM_USER_DATA = "USER.FAILED_GET_PARENT_MOIM_USER_DATA",

  START_GET_PROFILE_BLOCKS = "USER.START_GET_PROFILE_BLOCKS",
  SUCCEEDED_GET_PROFILE_BLOCKS = "USER.SUCCEEDED_GET_PROFILE_BLOCKS",
  FAILED_GET_PROFILE_BLOCKS = "USER.FAILED_GET_PROFILE_BLOCKS",

  START_SEARCH_PAGE_SEARCH_USERS = "USER.START_SEARCH_PAGE_SEARCH_USERS",
  SUCCEED_SEARCH_PAGE_SEARCH_USERS = "USER.SUCCEED_SEARCH_PAGE_SEARCH_USERS",
  FAILED_SEARCH_PAGE_SEARCH_USERS = "USER.FAILED_SEARCH_PAGE_SEARCH_USERS",
  CLEAR_SEARCH_PAGE_SEARCH_USERS = "USER.CLEAR_SEARCH_PAGE_SEARCH_USERS",

  START_SEARCH_USER_THREADS = "USER.START_SEARCH_USER_THREADS",
  SUCCEED_SEARCH_USER_THREADS = "USER.SUCCEED_SEARCH_USER_THREADS",
  FAILED_SEARCH_USER_THREADS = "USER.FAILED_SEARCH_USER_THREADS",
  CLEAR_SEARCH_USER_THREADS = "USER.CLEAR_SEARCH_USER_THREADS",
  STORE_SEARCH_USER_THREADS_COUNT = "USER.STORE_SEARCH_USER_THREADS_COUNT",
}

export enum EntityTypes {
  ADD_ENTITY = "ENTITY.ADD_ENTITY",
}

export enum AppTypes {
  CURRENT_GROUP_CHANGED = "APP.CURRENT_GROUP_CHANGED",
  PARENT_GROUP_CHANGED = "PARENT_GROUP_CHANGED",

  SIGN_OUT = "SIGN_OUT",
  CHANGE_TEXT_KEY_VISIBLE = "CHANGE_TRANSLATE",
  CHANGE_LOCALE = "CHANGE_LOCALE",
  CHANGE_LOCAL_CURRENCY = "CHANGE_LOCAL_CURRENCY",

  SUCCEED_USER_BLOCK = "APP.SUCCEED_USER_BLOCK",
  SUCCEED_USER_UNBLOCK = "APP.SUCCEED_USER_UNBLOCK",

  USER_GLOBAL_MUTE_NOTIFICATION = "APP.USER_GLOBAL_MUTE_NOTIFICATION",

  INITIALIZE_HISTORY = "INITIALIZE_HISTORY",
  PUSH_HISTORY = "PUSH_HISTORY",

  LOCATION_CHANGE = "@@router/LOCATION_CHANGE",
  START_REFRESH_TOKEN = "APP.START_REFRESH_TOKEN",
  SUCCEED_REFRESH_TOKEN = "APP.SUCCEED_REFRESH_TOKEN",
  FAILED_REFRESH_TOKEN = "APP.FAILED_REFRESH_TOKEN",

  SET_PROVIDERS = "APP.SET_PROVIDERS",
  SET_APP_CONFIG = "APP.SET_APP_CONFIG",
}

export enum AuthTypes {
  START_REFRESH_TOKEN = "APP.START_REFRESH_TOKEN",
  SUCCEED_REFRESH_TOKEN = "APP.SUCCEED_REFRESH_TOKEN",
  FAILED_REFRESH_TOKEN = "APP.FAILED_REFRESH_TOKEN",

  START_LOGGING_IN = "START_LOGGING_IN",
  END_LOGGING_IN = "END_LOGGING_IN",
  SIGN_OUT = "SIGN_OUT",

  CURRENT_USER_CHANGED = "CURRENT_USER_CHANGED",
  PARENT_MOIM_USER_CHANGED = "PARENT_MOIM_USER_CHANGED",

  START_GET_AUTHENTICATION = "APP.START_GET_AUTHENTICATION",
  SUCCEED_GET_AUTHENTICATION = "APP.SUCCEED_GET_AUTHENTICATION",
  FAILED_GET_AUTHENTICATION = "APP.FAILED_GET_AUTHENTICATION",
}

export enum SecondaryViewTypes {
  NATIVE_OPEN = "SECONDARY_VIEW.NATIVE_OPEN",
  NATIVE_CLOSE = "SECONDARY_VIEW.NATIVE_CLOSE",
  PLUGIN_OPEN = "SECONDARY_VIEW.PLUGIN_OPEN",
  PLUGIN_CLOSE = "SECONDARY_VIEW.PLUGIN_CLOSE",
  PLUGIN_BACK = "SECONDARY_VIEW.PLUGIN_BACK",

  PLUGIN_REDIRECTION_RESTORE = "SECONDARY_VIEW.PLUGIN_REDIRECTION_RESTORE",
  SET_PLUGIN_CONTENT_REF = "SECONDARY_VIEW.SET_PLUGIN_CONTENT_REF",

  NATIVE_OPEN_FROM_PROFILE = "SECONDARY_VIEW.NATIVE_OPEN_FROM_PROFILE",
}

export enum ToastActionTypes {
  PUSH_MESSAGE_TO_NOTIFICATION_STACK = "PUSH_MESSAGE_TO_NOTIFICATION_STACK",
  REMOVE_MESSAGE_TO_NOTIFICATION_STACK = "REMOVE_MESSAGE_TO_NOTIFICATION_STACK",
}

export enum ContentsGroupTypes {
  START_FETCH_CONTENTS_GROUP_THREADS = "CONTENTS_GROUP.START_FETCH_CONTENTS_GROUP_THREADS",
  SUCCEED_FETCH_CONTENTS_GROUP_THREADS = "CONTENTS_GROUP.SUCCEED_FETCH_CONTENTS_GROUP_THREADS",
  FAILED_FETCH_CONTENTS_GROUP_THREADS = "CONTENTS_GROUP.FAILED_FETCH_CONTENTS_GROUP_THREADS",

  START_FETCH_CONTENTS_GROUP = "CONTENTS_GROUP.START_FETCH_CONTENTS_GROUP",
  SUCCEED_FETCH_CONTENTS_GROUP = "CONTENTS_GROUP.SUCCEED_FETCH_CONTENTS_GROUP",
  FAILED_FETCH_CONTENTS_GROUP = "CONTENTS_GROUP.FAILED_FETCH_CONTENTS_GROUP",
}

export enum ChildMoimGroupTypes {
  START_FETCH_CHILD_MOIM_GROUP_MOIMS = "CONTENTS_GROUP.START_FETCH_CHILD_MOIM_GROUP_MOIMS",
  SUCCEED_FETCH_CHILD_MOIM_GROUP_MOIMS = "CONTENTS_GROUP.SUCCEED_FETCH_CHILD_MOIM_GROUP_MOIMS",
  FAILED_FETCH_CHILD_MOIM_GROUP_MOIMS = "CONTENTS_GROUP.FAILED_FETCH_CHILD_MOIM_GROUP_MOIMS",

  START_FETCH_CHILD_MOIM_GROUP = "CONTENTS_GROUP.START_FETCH_CHILD_MOIM_GROUP",
  SUCCEED_FETCH_CHILD_MOIM_GROUP = "CONTENTS_GROUP.SUCCEED_FETCH_CHILD_MOIM_GROUP",
  FAILED_FETCH_CHILD_MOIM_GROUP = "CONTENTS_GROUP.FAILED_FETCH_CHILD_MOIM_GROUP",
}

export enum ForumTypes {
  CHANGE_FORUM_ID = "FORUM.CHANGE_FORUM_ID",
  CHANGE_THREAD_ID = "FORUM.CHANGE_THREAD_ID",

  START_GET_THREAD_LIST = "FORUM.START_GET_THREAD_LIST",
  SUCCEED_GET_THREAD_LIST = "FORUM.SUCCEED_GET_THREAD_LIST",
  FAILED_GET_THREAD_LIST = "FORUM.FAILED_GET_THREAD_LIST",
  CLEAR_THREAD_LIST = "FORUM.CLEAR_THREAD_LIST",

  START_GET_COMMENT_LIST = "FORUM.START_GET_COMMENT_LIST",
  SUCCEED_GET_COMMENT_LIST = "FORUM.SUCCEED_GET_COMMENT_LIST",
  FAILED_GET_COMMENT_LIST = "FORUM.FAILED_GET_COMMENT_LIST",
  CLEAR_COMMENT_LIST = "FORUM.CLEAR_COMMENT_LIST",

  START_POST_COMMENT = "FORUM.START_POST_COMMENT",
  SUCCEED_POST_COMMENT = "FORUM.SUCCEED_POST_COMMENT",
  FAILED_POST_COMMENT = "FORUM.FAILED_POST_COMMENT",

  START_EDIT_COMMENT = "FORUM.START_EDIT_COMMENT",
  SUCCEED_EDIT_COMMENT = "FORUM.SUCCEED_EDIT_COMMENT",
  FAILED_EDIT_COMMENT = "FORUM.FAILED_POST_COMMENT",

  START_GET_THREAD = "FORUM.START_GET_THREAD",
  SUCCEEDED_GET_THREAD = "FORUM.SUCCEEDED_GET_THREAD",
  FAILED_GET_THREAD = "FORUM.FAILED_GET_THREAD",

  START_VOTE_THREAD = "FORUM.START_VOTE_THREAD",
  SUCCEEDED_VOTE_THREAD = "FORUM.SUCCEEDED_VOTE_THREAD",
  FAILED_VOTE_THREAD = "FORUM.FAILED_VOTE_THREAD",

  START_GET_THREAD_VOTES = "FORUM.START_GET_THREAD_VOTES",
  SUCCEEDED_GET_THREAD_VOTES = "FORUM.SUCCEEDED_GET_THREAD_VOTES",
  FAILED_GET_THREAD_VOTES = "FORUM.FAILED_GET_THREAD_VOTES",

  START_VOTE_REPLY = "FORUM.START_VOTE_REPLY",
  SUCCEEDED_VOTE_REPLY = "FORUM.SUCCEEDED_VOTE_REPLY",
  FAILED_VOTE_REPLY = "FORUM.FAILED_VOTE_REPLY",

  START_GET_REPLY_VOTES = "FORUM.START_GET_REPLY_VOTES",
  SUCCEEDED_GET_REPLY_VOTES = "FORUM.SUCCEEDED_GET_REPLY_VOTES",
  FAILED_GET_REPLY_VOTES = "FORUM.FAILED_GET_REPLY_VOTES",

  OPEN_VOTED_DIALOG = "FORUM.OPEN_VOTED_DIALOG",
  CLOSE_VOTED_DIALOG = "FORUM.CLOSE_VOTED_DIALOG",
  CLEAR_VOTED_DIALOG_DATA = "FORUM.CLEAR_VOTED_DIALOG_DATA ",

  ADD_THREAD = "FORUM.ADD_THREAD",
  CLEAR_CURRENT_THREAD = "FORUM.CLEAR_CURRENT_THREAD",

  ADD_NEW_THREAD = "FORUM.ADD_NEW_THREAD",
  READ_NEW_THREAD = "FORUM.READ_NEW_THREAD",

  START_DELETE_THREAD = "FORUM.START_DELETE_THREAD",
  SUCCEED_DELETE_THREAD = "FORUM.SUCCEED_DELETE_THREAD",
  FAILED_DELETE_THREAD = "FORUM.FAILED_DELETE_THREAD",

  START_DELETE_REPLY = "FORUM.START_DELETE_REPLY",
  SUCCEED_DELETE_REPLY = "FORUM.SUCCEED_DELETE_REPLY",
  FAILED_DELETE_REPLY = "FORUM.FAILED_DELETE_REPLY",

  CHANGE_FILTER_OPTION = "FORUM.CHANGE_FILTER_OPTION",

  OPEN_NEW_ITEM_SNACKBAR = "FORUM.OPEN_NEW_ITEM_SNACKBAR",
  CLOSE_NEW_ITEM_SNACKBAR = "FORUM.CLOSE_NEW_ITEM_SNACKBAR",
  SET_NEW_ITEM_SNACKBAR_DIRECTION = "FORUM.SET_NEW_ITEM_SNACKBAR_DIRECTION",

  CLEAR_COMMENT_EDIT_STATE = "FORUM.CLEAR_COMMENT_EDIT_STATE",
  CHANGE_COMMENT_EDIT_STATE = "FORUM.CHANGE_COMMENT_EDIT_STATE",

  START_PIN_POST = "CHANNEL.START_PIN_POST",
  SUCCEEDED_PIN_POST = "CHANNEL.SUCCEEDED_PIN_POST",
  FAILED_PIN_POST = "CHANNEL.FAILED_PIN_POST",

  START_GET_PINNED_POST_LIST = "CHANNEL.START_GET_PINNED_POST_LIST",
  SUCCEEDED_GET_PINNED_POST_LIST = "CHANNEL.SUCCEEDED_GET_PINNED_POST_LIST",
  FAILED_GET_PINNED_POST_LIST = "CHANNEL.FAILED_GET_PINNED_POST_LIST",

  START_DELETE_PINNED_POST = "CHANNEL.START_DELETE_PINNED_POST",
  SUCCEEDED_DELETE_PINNED_POST = "CHANNEL.SUCCEEDED_DELETE_PINNED_POST",
  FAILED_DELETE_PINNED_POST = "CHANNEL.FAILED_DELETE_PINNED_POST",

  START_ARRANGE_PINNED_POST_LIST = "CHANNEL.START_ARRANGE_PINNED_POST_LIST",
  SUCCEEDED_ARRANGE_PINNED_POST_LIST = "CHANNEL.SUCCEEDED_ARRANGE_PINNED_POST_LIST",
  FAILED_ARRANGE_PINNED_POST_LIST = "CHANNEL.FAILED_ARRANGE_PINNED_POST_LIST",

  OPEN_EDIT_PINNED_POST_LIST_DIALOG = "FORUM.OPEN_EDIT_PINNED_POST_LIST_DIALOG",
  CLOSE_EDIT_PINNED_POST_LIST_DIALOG = "FORUM.CLOSE_EDIT_PINNED_POST_LIST_DIALOG",

  UPDATE_HIGHLIGHT_THREAD_ID = "FORUM.UPDATE_HIGHLIGHT_THREAD_ID",
  CLEAR_HIGHLIGHT_THREAD_ID = "FORUM.CLEAR_HIGHLIGHT_THREAD_ID",

  START_SEARCH_THREADS = "FORUM.START_SEARCH_THREADS",
  SUCCEED_SEARCH_THREADS = "FORUM.SUCCEED_SEARCH_THREADS",
  FAILED_SEARCH_THREADS = "FORUM.FAILED_SEARCH_THREADS",
  CLEAR_SEARCH_THREADS = "FORUM.CLEAR_SEARCH_THREADS",

  START_FETCH_SCRAP = "FORUM.START_FETCH_SCRAP",
  SUCCEED_FETCH_SCRAP = "FORUM.SUCCEED_FETCH_SCRAP",
  FAILED_FETCH_SCRAP = "FORUM.FAILED_FETCH_SCRAP",
}

export enum ThreadTypes {
  START_FETCH_UNLOCKED_CONTENTS = "THREAD.START_FETCH_UNLOCKED_CONTENTS",
  SUCCEED_FETCH_UNLOCKED_CONTENTS = "THREAD.SUCCEED_FETCH_UNLOCKED_CONTENTS",
  FAILED_FETCH_UNLOCKED_CONTENTS = "THREAD.FAILED_FETCH_UNLOCKED_CONTENTS",
}

export enum ForumDraftTypes {
  START_SAVE_DRAFT = "DRAFT.START_SAVE_DRAFT",
  SUCCEEDED_SAVE_DRAFT = "DRAFT.SUCCEEDED_SAVE_DRAFT",
  FAILED_SAVE_DRAFT = "DRAFT.FAILED_SAVE_DRAFT",

  START_UPDATE_DRAFT = "DRAFT.START_UPDATE_DRAFT",
  SUCCEEDED_UPDATE_DRAFT = "DRAFT.SUCCEEDED_UPDATE_DRAFT",
  UPDATE_SILENT_DRAFT_EDIT_AT = "DRAFT.UPDATE_SILENT_DRAFT_EDIT_AT",
  FAILED_UPDATE_DRAFT = "DRAFT.FAILED_UPDATE_DRAFT",

  START_DELETE_DRAFT = "DRAFT.START_DELETE_DRAFT",
  SUCCEEDED_DELETE_DRAFT = "DRAFT.SUCCEEDED_DELETE_DRAFT",
  FAILED_DELETE_DRAFT = "DRAFT.FAILED_DELETE_DRAFT",

  START_GET_DRAFT_LIST = "DRAFT.START_GET_DRAFT_LIST",
  SUCCEEDED_GET_DRAFT_LIST = "DRAFT.SUCCEEDED_GET_DRAFT_LIST",
  FAILED_GET_DRAFT_LIST = "DRAFT.FAILED_GET_DRAFT_LIST",

  START_GET_DRAFT = "DRAFT.START_GET_DRAFT",
  SUCCEEDED_GET_DRAFT = "DRAFT.SUCCEEDED_GET_DRAFT",
  FAILED_GET_DRAFT = "DRAFT.FAILED_GET_DRAFT",

  START_GET_DRAFT_COUNT = "DRAFT.START_GET_DRAFT_COUNT",
  SUCCEEDED_GET_DRAFT_COUNT = "DRAFT.SUCCEEDED_GET_DRAFT_COUNT",
  FAILED_GET_DRAFT_COUNT = "DRAFT.FAILED_GET_DRAFT_COUNT",

  START_DELETE_ALL_DRAFT = "DRAFT.START_DELETE_ALL_DRAFT",
  SUCCEEDED_DELETE_ALL_DRAFT = "DRAFT.SUCCEEDED_DELETE_ALL_DRAFT",
  FAILED_DELETE_ALL_DRAFT = "DRAFT.FAILED_DELETE_ALL_DRAFT",

  SOFT_DELETE_DRAFT = "DRAFT.SOFT_DELETE_DRAFT",

  OPEN_DRAFT_LIST_MODAL = "DRAFT.OPEN_DRAFT_LIST_MODAL",
  CLOSE_DRAFT_LIST_MODAL = "DRAFT.CLOSE_DRAFT_LIST_MODAL",

  SET_CURRENT_DRAFT = "DRAFT.SET_CURRENT_DRAFT",
}

export enum ForumCommentPageTypes {
  RESET_JUST_ADDED = "FORUM_COMMENT_PAGE.RESET_JUST_ADDED",
}

export enum ForumEditorTypes {
  CLEAR_POSTING_THREAD = "FORUM_EDITOR.CLEAR_POSTING_THREAD",
  START_POSTING_THREAD = "FORUM_EDITOR.START_POSTING_THREAD",
  SUCCEEDED_POSTING_THREAD = "FORUM_EDITOR.SUCCEEDED_POSTING_THREAD",
  FAILED_POSTING_THREAD = "FORUM_EDITOR.FAILED_POSTING_THREAD",

  START_UPDATE_THREAD = "FORUM_EDITOR.START_UPDATE_THREAD",
  SUCCEEDED_UPDATE_THREAD = "FORUM_EDITOR.SUCCEEDED_UPDATE_THREAD",
  FAILED_UPDATE_THREAD = "FORUM_EDITOR.FAILED_UPDATE_THREAD",
}

export enum ConversationTypes {
  START_GET_CONVERSATION = "CONVERSATIONS.START_GET_CONVERSATION",
  SUCCEED_GET_CONVERSATION = "CONVERSATIONS.SUCCEED_GET_CONVERSATION",
  FAILED_GET_CONVERSATION = "CONVERSATIONS.FAILED_GET_CONVERSATION",
  START_GET_CONVERSATION_MEMBERS = "CONVERSATIONS.START_GET_CONVERSATION_MEMBERS",
  SUCCEED_GET_CONVERSATION_MEMBERS = "CONVERSATIONS.SUCCEED_GET_CONVERSATION_MEMBERS",
  FAILED_GET_CONVERSATION_MEMBERS = "CONVERSATIONS.FAILED_GET_CONVERSATION_MEMBERS",
  START_JOIN_CONVERSATION = "CONVERSATIONS.START_JOIN_CONVERSATION",
  SUCCEED_JOIN_CONVERSATION = "CONVERSATIONS.SUCCEED_JOIN_CONVERSATION",
  FAILED_JOIN_CONVERSATION = "CONVERSATIONS.FAILED_JOIN_CONVERSATION",

  START_GET_CONVERSATION_MESSAGES = "CONVERSATIONS.START_GET_CONVERSATION_MESSAGES",
  SUCCEED_GET_CONVERSATION_MESSAGES = "CONVERSATIONS.SUCCEED_GET_CONVERSATION_MESSAGES",
  FAILED_GET_CONVERSATION_MESSAGES = "CONVERSATIONS.FAILED_GET_CONVERSATION_MESSAGES",

  START_GET_CONVERSATION_MESSAGE = "CONVERSATIONS.START_GET_CONVERSATION_MESSAGE",
  SUCCEED_GET_CONVERSATION_MESSAGE = "CONVERSATIONS.SUCCEED_GET_CONVERSATION_MESSAGE",
  FAILED_GET_CONVERSATION_MESSAGE = "CONVERSATIONS.FAILED_GET_CONVERSATION_MESSAGE",

  START_CREATE_CONVERSATION_MESSAGE = "CONVERSATIONS.START_CREATE_CONVERSATION_MESSAGE",
  SUCCEED_CREATE_CONVERSATION_MESSAGE = "CONVERSATIONS.SUCCEED_CREATE_CONVERSATION_MESSAGE",
  FAILED_CREATE_CONVERSATION_MESSAGE = "CONVERSATIONS.FAILED_CREATE_CONVERSATION_MESSAGE",

  START_EDIT_CONVERSATION_MESSAGE = "CONVERSATIONS.START_EDIT_CONVERSATION_MESSAGE",
  SUCCEED_EDIT_CONVERSATION_MESSAGE = "CONVERSATIONS.SUCCEED_EDIT_CONVERSATION_MESSAGE",
  FAILED_EDIT_CONVERSATION_MESSAGE = "CONVERSATIONS.FAILED_EDIT_CONVERSATION_MESSAGE",

  START_DELETE_CONVERSATION_MESSAGE = "CONVERSATIONS.START_DELETE_CONVERSATION_MESSAGE",
  SUCCEED_DELETE_CONVERSATION_MESSAGE = "CONVERSATIONS.SUCCEED_DELETE_CONVERSATION_MESSAGE",
  FAILED_DELETE_CONVERSATION_MESSAGE = "CONVERSATIONS.FAILED_DELETE_CONVERSATION_MESSAGE",

  CHANGE_MESSAGE_EDIT_STATE = "CONVERSATIONS.CHANGE_MESSAGE_EDIT_STATE",
  CLEAR_MESSAGE_EDIT_STATE = "CONVERSATIONS.CLEAR_MESSAGE_EDIT_STATE",

  START_SEARCH_MESSAGES = "CONVERSATIONS.START_SEARCH_MESSAGES",
  SUCCEED_SEARCH_MESSAGES = "CONVERSATIONS.SUCCEED_SEARCH_MESSAGES",
  FAILED_SEARCH_MESSAGES = "CONVERSATIONS.FAILED_SEARCH_MESSAGES",
  CLEAR_SEARCH_MESSAGES = "CONVERSATIONS.CLEAR_SEARCH_MESSAGES",
}

export enum MeTypes {
  OPEN_EDIT_MY_PROFILE = "ME.OPEN_EDIT_MY_PROFILE",
  CLOSE_EDIT_MY_PROFILE = "ME.CLOSE_EDIT_MY_PROFILE",

  START_CHANGE_MY_PROFILE = "ME.START_CHANGE_MY_PROFILE",
  SUCCEEDED_CHANGE_MY_PROFILE = "ME.SUCCEEDED_CHANGE_MY_PROFILE",
  FAILED_CHANGE_MY_PROFILE = "ME.FAILED_CHANGE_MY_PROFILE",

  START_UPDATE_AVATAR = "ME.START_UPDATE_AVATAR",
  SUCCEEDED_UPDATE_AVATAR = "ME.SUCCEEDED_UPDATE_AVATAR",
  FAILED_UPDATE_AVATAR = "ME.FAILED_UPDATE_AVATAR",

  START_GET_MY_JOINED_MOIMS = "ME.START_GET_MY_JOINED_MOIMS",
  SUCCEEDED_GET_MY_JOINED_MOIMS = "ME.SUCCEEDED_GET_MY_JOINED_MOIMS",
  FAILED_GET_MY_JOINED_MOIMS = "ME.FAILED_GET_MY_JOINED_MOIMS",

  ADD_MY_JOINED_MOIM = "ME.ADD_MY_JOINED_MOIM",

  START_LEAVE_MOIM = "ME.START_LEAVE_MOIM",
  SUCCEED_LEAVE_MOIM = "ME.SUCCEED_LEAVE_MOIM",
  FAILED_LEAVE_MOIM = "ME.FAILED_LEAVE_MOIM",

  OPEN_FAILED_LEAVE_MOIM_ALERT = "ME.OPEN_FAILED_LEAVE_MOIM_ALERT",
  CLOSE_FAILED_LEAVE_MOIM_ALERT = "ME.CLOSE_FAILED_LEAVE_MOIM_ALERT",

  START_GET_BOOKMARKS = "ME.START_GET_BOOKMARKS",
  SUCCEEDED_GET_BOOKMARKS = "ME.SUCCEEDED_GET_BOOKMARKS",
  FAILED_GET_BOOKMARKS = "ME.FAILED_GET_BOOKMARKS",
  START_POST_BOOKMARK = "ME.START_POST_BOOKMARK",
  SUCCEEDED_POST_BOOKMARK = "ME.SUCCEEDED_POST_BOOKMARK",
  FAILED_POST_BOOKMARK = "ME.FAILED_POST_BOOKMARK",
  START_DELETE_BOOKMARK = "ME.START_DELETE_BOOKMARK",
  SUCCEEDED_DELETE_BOOKMARK = "ME.SUCCEEDED_DELETE_BOOKMARK",
  FAILED_DELETE_BOOKMARK = "ME.FAILED_DELETE_BOOKMARK",
  START_ALL_MOIM_RESOURCES = "ME.START_ALL_MOIM_RESOURCES",
  SUCCEEDED_ALL_MOIM_RESOURCES = "ME.SUCCEEDED_ALL_MOIM_RESOURCES",
  FAILED_ALL_MOIM_RESOURCES = "ME.FAILED_ALL_MOIM_RESOURCES",

  CHANGE_BOOKMAKR_CURRENT_MOIM_RESOURCE = "ME.CHANGE_BOOKMAKR_CURRENT_MOIM_RESOURCE",
}

export enum ProfileTypes {
  START_FETCHING_PROFILE = "PROFILE.START_FETCHING_PROFILE",
  SUCCEED_FETCHING_PROFILE = "PROFILE.SUCCEED_FETCHING_PROFILE",
  FAILED_FETCHING_PROFILE = "PROFILE.FAILED_FETCHING_PROFILE",

  OPEN_PROFILE_DIALOG = "PROFILE.OPEN_PROFILE_DIALOG",
  CLOSE_PROFILE_DIALOG = "PROFILE.CLOSE_PROFILE_DIALOG",
}

export enum ImageBrochureTypes {
  OPEN_IMAGE_BROCHURE_DIALOG = "IMAGE_BROCHURE.OPEN_IMAGE_BROCHURE_DIALOG",
  OPEN_SRC_IMAGE_BROCHURE_DIALOG = "IMAGE_BROCHURE.OPEN_SRC_IMAGE_BROCHURE_DIALOG",
  CLOSE_IMAGE_BROCHURE_DIALOG = "IMAGE_BROCHURE.CLOSE_IMAGE_BROCHURE_DIALOG",
}

export enum SideNavigationTypes {
  EXPAND_SIDE_NAVIGATION = "SIDE_NAVIGATION.EXPAND_SIDE_NAVIGATION",
  COLLAPSE_SIDE_NAVIGATION = "SIDE_NAVIGATION.COLLAPSE_SIDE_NAVIGATION",
}

export enum FileUploadTypes {
  START_FILE_UPLOAD = "FILE_UPLOAD.START_FILE_UPLOAD",
  END_FILE_UPLOAD = "FILE_UPLOAD.END_FILE_UPLOAD",
  FAILED_FILE_UPLOAD = "FILE_UPLOAD.FAILED_FILE_UPLOAD",
  SET_FILE_UPLOAD_STATUS = "FILE_UPLOAD.SET_FILE_UPLOAD_STATUS",
  DELETE_FILE = "FILE_UPLOAD.DELETE_FILE",
}

export enum SnackbarTypes {
  OPEN_SNACKBAR = "SNACKBAR.OPEN_SNACKBAR",
  CLOSE_SNACKBAR = "SNACKBAR.CLOSE_SNACKBAR",
}

export enum ChannelFormDialogTypes {
  OPEN_FOR_CREATE = "CHANNEL_FORM_DIALOG.OPEN_FOR_CREATE",
  OPEN_FOR_EDIT = "CHANNEL_FORM_DIALOG.OPEN_FOR_EDIT",
  CLOSE = "CHANNEL_FORM_DIALOG.CLOSE_CHANNEL_FORM_DIALOG",
}

export enum PositionApplyDialogTypes {
  OPEN = "POSITION_APPLY_DIALOG.OPEN",
  CLOSE = "POSITION_APPLY_DIALOG.CLOSE",
}

export enum PositionTypes {
  START_GET_POSITIONS = "POSITION.START_GET_POSITIONS",
  SUCCEED_GET_POSITIONS = "POSITION.SUCCEED_GET_POSITIONS",
  FAILED_GET_POSITIONS = "POSITION.FAILED_GET_POSITIONS",

  START_GET_POSITION = "POSITION.START_GET_POSITION",
  SUCCEED_GET_POSITION = "POSITION.SUCCEED_GET_POSITION",
  FAILED_GET_POSITION = "POSITION.FAILED_GET_POSITION",

  START_GET_POSITIONS_BATCH = "POSITION.START_GET_POSITIONS_BATCH",
  SUCCEED_GET_POSITIONS_BATCH = "POSITION.SUCCEED_POSITIONS_BATCH",
  FAILED_GET_POSITIONS_BATCH = "POSITION.FAILED_GET_POSITIONS_BATCH",

  START_CREATE_POSITION = "POSITION.START_CREATE_POSITION",
  SUCCEED_CREATE_POSITION = "POSITION.SUCCEED_CREATE_POSITION",
  FAILED_CREATE_POSITION = "POSITION.FAILED_CREATE_POSITION",

  START_APPOINT_POSITION = "POSITION.START_APPOINT_POSITION",
  SUCCEED_APPOINT_POSITION = "POSITION.SUCCEED_APPOINT_POSITION",
  FAILED_APPOINT_POSITION = "POSITION.FAILED_APPOINT_POSITION",

  START_DISMISS_POSITION = "POSITION.START_DISMISS_POSITION",
  SUCCEED_DISMISS_POSITION = "POSITION.SUCCEED_DISMISS_POSITION",
  FAILED_DISMISS_POSITION = "POSITION.FAILED_DISMISS_POSITION",

  START_UPDATE_POSITION_INFO = "POSITION.START_UPDATE_POSITION_INFO",
  SUCCEED_UPDATE_POSITION_INFO = "POSITION.SUCCEED_UPDATE_POSITION_INFO",
  FAILED_UPDATE_POSITION_INFO = "POSITION.FAILED_UPDATE_POSITION_INFO",

  START_UPDATE_POSITION_PRIORITY = "POSITION.START_UPDATE_POSITION_PRIORITY",
  SUCCEED_UPDATE_POSITION_PRIORITY = "POSITION.SUCCEED_UPDATE_POSITION_PRIORITY",
  FAILED_UPDATE_POSITION_PRIORITY = "POSITION.FAILED_UPDATE_POSITION_PRIORITY",

  START_DELETE_POSITION = "POSITION.START_DELETE_POSITION",
  SUCCEED_DELETE_POSITION = "POSITION.SUCCEED_DELETE_POSITION",
  FAILED_DELETE_POSITION = "POSITION.FAILED_DELETE_POSITION",
  CLEAR_DELETE_POSITION_ERROR = "POSITION.CLEAR_DELETE_POSITION_ERROR",

  START_GET_POSITION_MEMBERS = "POSITION.START_GET_POSITION_MEMBERS",
  SUCCEED_GET_POSITION_MEMBERS = "POSITION.SUCCEED_POSITION_MEMBERS",
  FAILED_GET_POSITION_MEMBERS = "POSITION.FAILED_GET_POSITION_MEMBERS",
}

export enum ThemeTypes {
  SET_COLOR = "THEME.SET_COLOR",
  SET_SIDE_NAVIGATION_HEADER = "THEME.SET_SIDE_NAVIGATION_HEADER",
  SET_SIDE_NAVIGATION_BACKGROUND_COLOR = "THEME.SET_SIDE_NAVIGATION_BACKGROUND_COLOR",
}

export enum PositionFormDialogTypes {
  OPEN_FOR_CREATE = "POSITION_FORM_DIALOG.OPEN_FOR_CREATE",
  OPEN_FOR_EDIT = "POSITION_FORM_DIALOG.OPEN_FOR_EDIT",
  CLOSE = "POSITION_FORM_DIALOG.CLOSE",
}

export enum TagTypes {
  CLEAR_SUBGROUPS = "TAG.CLEAR_SUBGROUPS",

  START_GET_TAGS = "TAG.START_GET_TAGS",
  SUCCEEDED_GET_TAGS = "TAG.SUCCEEDED_GET_TAGS",
  FAILED_GET_TAGS = "TAG.FAILED_GET_TAGS",

  START_CREATE_TAG = "TAG.START_CREATE_TAG",
  SUCCEEDED_CREATE_TAG = "TAG.SUCCEEDED_CREATE_TAG",
  FAILED_CREATE_TAG = "TAG.FAILED_CREATE_TAG",

  START_UPDATE_TAG = "TAG.START_UPDATE_TAG",
  SUCCEEDED_UPDATE_TAG = "TAG.SUCCEEDED_UPDATE_TAG",
  FAILED_UPDATE_TAG = "TAG.FAILED_UPDATE_TAG",

  START_GET_SUBGROUPS_FROM_TAG = "TAG.START_GET_SUBGROUPS_FROM_TAG",
  SUCCEEDED_GET_SUBGROUPS_FROM_TAG = "TAG.SUCCEEDED_GET_SUBGROUPS_FROM_TAG",
  FAILED_GET_SUBGROUPS_FROM_TAG = "TAG.FAILED_GET_SUBGROUPS_FROM_TAG",

  START_GET_SUBGROUPS_FROM_TAGS = "TAG.START_GET_SUBGROUPS_FROM_TAGS",
  SUCCEEDED_GET_SUBGROUPS_FROM_TAGS = "TAG.SUCCEEDED_GET_SUBGROUPS_FROM_TAGS",
  FAILED_GET_SUBGROUPS_FROM_TAGS = "TAG.FAILED_GET_SUBGROUPS_FROM_TAGS",

  START_REGISTER_SUBGROUPS_TO_TAG = "TAG.START_REGISTER_SUBGROUPS_TO_TAG",
  SUCCEEDED_REGISTER_SUBGROUPS_TO_TAG = "TAG.SUCCEEDED_REGISTER_SUBGROUPS_TO_TAG",
  FAILED_REGISTER_SUBGROUPS_TO_TAG = "TAG.FAILED_REGISTER_SUBGROUPS_TO_TAG",

  START_UNREGISTER_SUBGROUPS_TO_TAG = "TAG.START_UNREGISTER_SUBGROUPS_TO_TAG",
  SUCCEEDED_UNREGISTER_SUBGROUPS_TO_TAG = "TAG.SUCCEEDED_UNREGISTER_SUBGROUPS_TO_TAG",
  FAILED_UNREGISTER_SUBGROUPS_TO_TAG = "TAG.FAILED_REGISTER_SUBGROUPS_TO_TAG",
}

export enum DirectMessageTypes {
  CLEAR_DIRECT_MESSAGE = "DIRECT_MESSAGE.CLEAR_DIRECT_MESSAGE",

  START_GET_DIRECT_MESSAGES = "DIRECT_MESSAGE.START_GET_DIRECT_MESSAGES",
  SUCCEEDED_GET_DIRECT_MESSAGES = "DIRECT_MESSAGE.SUCCEEDED_GET_DIRECT_MESSAGES",
  FAILED_GET_DIRECT_MESSAGES = "DIRECT_MESSAGE.FAILED_GET_DIRECT_MESSAGES",

  START_GET_DIRECT_MESSAGE = "DIRECT_MESSAGE.START_GET_DIRECT_MESSAGE",
  SUCCEEDED_GET_DIRECT_MESSAGE = "DIRECT_MESSAGE.SUCCEEDED_GET_DIRECT_MESSAGE",
  FAILED_GET_DIRECT_MESSAGE = "DIRECT_MESSAGE.FAILED_GET_DIRECT_MESSAGES",

  START_CREATE_DIRECT_MESSAGE = "DIRECT_MESSAGE.START_CREATE_DIRECT_MESSAGE",
  SUCCEEDED_CREATE_DIRECT_MESSAGE = "DIRECT_MESSAGE.SUCCEEDED_CREATE_DIRECT_MESSAGE",
  FAILED_CREATE_DIRECT_MESSAGE = "DIRECT_MESSAGE.FAILED_CREATE_DIRECT_MESSAGE",

  OPEN_NEW_DIRECT_MESSAGE_DIALOG = "GROUP.OPEN_NEW_DIRECT_MESSAGE_DIALOG",
  CLOSE_NEW_DIRECT_MESSAGE_DIALOG = "GROUP.CLOSE_NEW_DIRECT_MESSAGE_DIALOG",
}

export enum PermissionTypes {
  START_GET_PERMISSION = "PERMISSION.START_GET_PERMISSION",
  SUCCEED_GET_PERMISSION = "PERMISSION.SUCCEED_GET_PERMISSION",
  FAILED_GET_PERMISSION = "PERMISSION.FAILED_GET_PERMISSION",

  START_UPDATE_PERMISSION = "PERMISSION.START_UPDATE_PERMISSION",
  SUCCEED_UPDATE_PERMISSION = "PERMISSION.SUCCEED_UPDATE_PERMISSION",
  FAILED_UPDATE_PERMISSION = "PERMISSION.FAILED_UPDATE_PERMISSION",

  CLEAR_PERMISSION = "PERMISSION.CLEAR_PERMISSION",
}

export enum AgreementTypes {
  START_PUT_AGREEMENT = "AGREEMENT.START_PUT_AGREEMENT",
  SUCCEED_PUT_AGREEMENT = "AGREEMENT.SUCCEED_PUT_AGREEMENT",
  FAILED_PUT_AGREEMENT = "AGREEMENT.FAILED_PUT_AGREEMENT",
}

export enum CryptoBadgeTypes {
  START_FETCHING_CERTIFICATIONS = "CRYPTO_BADGE.START_FETCHING_CERTIFICATIONS",
  SUCCEED_FETCHING_CERTIFICATIONS = "CRYPTO_BADGE.SUCCEED_FETCHING_CERTIFICATIONS",
  FAILED_FETCHING_CERTIFICATIONS = "CRYPTO_BADGE.FAILED_FETCHING_CERTIFICATIONS",

  SUCCEED_FETCHING_CERTIFICATES_BY_ISSUERS = "CRYPTO_BADGE.SUCCEED_FETCHING_CERTIFICATES_BY_ISSUERS",
  SUCCEED_FETCHING_BADGES = "CRYPTO_BADGE.SUCCEED_FETCHING_BADGES",
  SUCCEED_FETCHING_BADGE_GROUP = "CRYPTO_BADGE.SUCCEED_FETCHING_BADGE_GROUP",
  SUCCEED_FETCHING_MINT_REQUESTS = "CRYPTO_BADGE.SUCCEED_FETCHING_MINT_REQUESTS",

  CLEAR_DATA_STATE = "CRYPTO_BADGE.CLEAR_DATA_STATE",
}

export enum NotificationTypes {
  START_FETCHING_NOTIFICATIONS = "NOTIFICATION.START_FETCHING_NOTIFICATIONS",
  SUCCEED_FETCHING_NOTIFICATIONS = "NOTIFICATION.SUCCEED_FETCHING_NOTIFICATIONS",
  FAILED_FETCHING_NOTIFICATIONS = "NOTIFICATION.FAILED_FETCHING_NOTIFICATIONS",

  OPEN_NOTIFICATIONS_DIALOG = "NOTIFICATION.OPEN_NOTIFICATIONS_DIALOG",
  CLOSE_NOTIFICATIONS_DIALOG = "NOTIFICATION.CLOSE_NOTIFICATIONS_DIALOG",

  READ_NOTIFICATION = "NOTIFICATION.READ_NOTIFICATION",
}

export enum ReferenceBlockTypes {
  START_FETCH_REFERENCE_BLOCK = "REFERENCE_BLOCK.START_FETCH_REFERENCE_BLOCK",
  SUCCEED_FETCH_REFERENCE_BLOCK = "REFERENCE_BLOCK.SUCCEED_FETCH_REFERENCE_BLOCK",
  FAILED_FETCH_REFERENCE_BLOCK = "REFERENCE_BLOCK.FAILED_FETCH_REFERENCE_BLOCK",

  START_CLICK_REFERENCE_ACTION = "REFERENCE_BLOCK.START_CLICK_REFERENCE_ACTION",
  SUCCEED_CLICK_REFERENCE_ACTION = "REFERENCE_BLOCK.SUCCEED_CLICK_REFERENCE_ACTION",
  FAILED_CLICK_REFERENCE_ACTION = "REFERENCE_BLOCK.FAILED_CLICK_REFERENCE_ACTION",

  START_FETCH_MORE_LIST_BLOCKS = "LIST_BLOCK.START_FETCH_MORE_LIST_BLOCKS",
  SUCCEED_FETCH_MORE_LIST_BLOCKS = "LIST_BLOCK.SUCCEED_FETCH_MORE_LIST_BLOCKS",
  FAILED_FETCH_MORE_LIST_BLOCKS = "LIST_BLOCK.FAILED_FETCH_MORE_LIST_BLOCKS",

  APPEND_LIST_BLOCKS = "REFERENCE_BLOCK.APPEND_LIST_BLOCKS",
  UPDATE_LIST_BLOCK = "REFERENCE_BLOCK.UPDATE_LIST_BLOCK",

  REPLACE_REFERENCE_BLOCK = "REFERENCE_BLOCK.REPLACE_REFERENCE_BLOCK",
  OPEN_BLOCKIT_MODAL = "REFERENCE_BLOCK.OPEN_BLOCKIT_MODAL",
  CLOSE_BLOCKIT_MODAL = "REFERENCE_BLOCK.CLOSE_BLOCKIT_MODAL",
  SHOW_TOAST = "REFERENCE_BLOCK.SHOW_TOAST",

  STORE_REDIRECT_BLOCKS = "REFERENCE_BLOCK.STORE_REDIRECT_BLOCKS",

  SET_BLOCKIT_MODAL_REF = "REFERENCE_BLOCK.SET_BLOCKIT_MODAL_REF",
}

export enum SNSShareDialogTypes {
  OPEN = "SNS_SHARE_DIALOG.OPEN",
  CLOSE = "SNS_SHARE_DIALOG.CLOSE",
}

export enum PurchasePreparationDialogTypes {
  OPEN = "PurchasePreparationDialogTypes.OPEN",
  CLOSE = "PurchasePreparationDialogTypes.CLOSE",
  UPDATE_PURCHASE_READY_ITEMS = "PurchasePreparationDialogTypes.UPDATE_PURCHASE_READY_ITEMS",
}

export enum ChannelNotificationSettingDialogTypes {
  OPEN = "CHANNEL_NOTIFICATION_SETTING_DIALOG.OPEN",
  CLOSE = "CHANNEL_NOTIFICATION_SETTING_DIALOG.CLOSE",
}

export enum MeetingTypes {
  START_CREATE_MEETING = "MEETING.START_CREATE_MEETING",
  SUCCEED_CREATE_MEETING = "MEETING.SUCCEED_CREATE_MEETING",
  FAILED_CREATE_MEETING = "MEETING.FAILED_CREATE_MEETING",

  START_FETCH_MEETING_DATA = "MEETING.START_FETCH_MEETING_DATA",
  SUCCEED_FETCH_MEETING_DATA = "MEETING.SUCCEED_FETCH_MEETING_DATA",
  FAILED_FETCH_MEETING_DATA = "MEETING.FAILED_FETCH_MEETING_DATA",

  START_CHANGE_HOST = "MEETING.START_CHANGE_HOST",
  SUCCEED_CHANGE_HOST = "MEETING.SUCCEED_CHANGE_HOST",
  FAILED_CHANGE_HOST = "MEETING.FAILED_CHANGE_HOST",

  START_CHANGE_MEETING_STATUS = "MEETING.START_CHANGE_MEETING_STATUS",
  SUCCEED_CHANGE_MEETING_STATUS = "MEETING.SUCCEED_CHANGE_MEETING_STATUS",
  FAILED_CHANGE_MEETING_STATUS = "MEETING.FAILED_CHANGE_MEETING_STATUS",

  START_CHANGE_USER_STATUS = "MEETING.START_CHANGE_USER_STATUS",
  SUCCEED_CHANGE_USER_STATUS = "MEETING.SUCCEED_CHANGE_USER_STATUS",
  FAILED_CHANGE_USER_STATUS = "MEETING.FAILED_CHANGE_USER_STATUS",

  START_JOIN_MEETING = "MEETING.START_JOIN_MEETING",
  SUCCEED_JOIN_MEETING = "MEETING.SUCCEED_JOIN_MEETING",
  FAILED_JOIN_MEETING = "MEETING.FAILED_JOIN_MEETING",

  START_END_MEETING = "MEETING.START_END_MEETING",
  SUCCEED_END_MEETING = "MEETING.SUCCEED_END_MEETING",
  FAILED_END_MEETING = "MEETING.FAILED_END_MEETING",

  START_LEAVE_MEETING = "MEETING.START_LEAVE_MEETING",
  SUCCEED_LEAVE_MEETING = "MEETING.SUCCEED_LEAVE_MEETING",
  FAILED_LEAVE_MEETING = "MEETING.FAILED_LEAVE_MEETING",

  START_LINK_MEETING = "MEETING.START_LINK_MEETING",
  SUCCEED_LINK_MEETING = "MEETING.SUCCEED_LINK_MEETING",
  FAILED_LINK_MEETING = "MEETING.FAILED_LINK_MEETING",

  HOST_COMMAND_STATUS = "MEETING.HOST_COMMAND_STATUS",
  HOST_ACTIONED = "MEETING.HOST_ACTIONED",

  CLEAR_LINK_MEETING = "MEETING.CLEAR_LINK_MEETING",
}

export enum CreateMeetingDialogTypes {
  OPEN_CREATE_MEETING_DIALOG = "OPEN_CREATE_MEETING_DIALOG",
  CLOSE_CREATE_MEETING_DIALOG = "CLOSE_CREATE_MEETING_DIALOG",
}

export enum PostTemplateTypes {
  START_POST_POST_TEAMPLATE = "POST_TEMPLATE.START_POST_POST_TEAMPLATE",
  SUCCEED_POST_POST_TEAMPLATE = "POST_TEMPLATE.SUCCEED_POST_POST_TEAMPLATE",
  FAILED_POST_POST_TEAMPLATE = "POST_TEMPLATE.FAILED_POST_POST_TEAMPLATE",

  START_GET_POST_TEAMPLATE_LIST = "POST_TEMPLATE.START_GET_POST_TEAMPLATE_LIST",
  SUCCEED_GET_POST_TEAMPLATE_LIST = "POST_TEMPLATE.SUCCEED_GET_POST_TEAMPLATE_LIST",
  FAILED_GET_POST_TEAMPLATE_LIST = "POST_TEMPLATE.FAILED_POST_POST_TEAMPLATE",

  START_UPDATE_POST_TEAMPLATE = "POST_TEMPLATE.START_UPDATE_POST_TEAMPLATE",
  SUCCEED_UPDATE_POST_TEAMPLATE = "POST_TEMPLATE.SUCCEED_UPDATE_POST_TEAMPLATE",
  FAILED_UPDATE_POST_TEAMPLATE = "POST_TEMPLATE.FAILED_UPDATE_POST_TEAMPLATE",

  START_DELETE_POST_TEAMPLATE = "POST_TEMPLATE.START_DELETE_POST_TEAMPLATE",
  SUCCEED_DELETE_POST_TEAMPLATE = "POST_TEMPLATE.SUCCEED_DELETE_POST_TEAMPLATE",
  FAILED_DELETE_POST_TEAMPLATE = "POST_TEMPLATE.FAILED_DELETE_POST_TEAMPLATE",
}

export enum CommerceTypes {
  START_GET_BASIC_INFO = "COMMERCE.START_GET_BASIC_INFO",
  SUCCEED_GET_BASIC_INFO = "COMMERCE.SUCCEED_GET_BASIC_INFO",
  FAILED_GET_BASIC_INFO = "COMMERCE.FAILED_GET_BASIC_INFO",

  OPEN_CHECKOUT_REDIRECT_LOADING_DIALOG_AS_COIN_RECHARGE = "COMMERCE.OPEN_CHECKOUT_REDIRECT_LOADING_DIALOG_AS_COIN_RECHARGE",
  OPEN_CHECKOUT_REDIRECT_LOADING_DIALOG_AS_CONFIRMATION = "COMMERCE.OPEN_CHECKOUT_REDIRECT_LOADING_DIALOG_AS_CONFIRMATION",

  OPEN_CHECKOUT_REDIRECT_LOADING_DIALOG = "COMMERCE.OPEN_CHECKOUT_REDIRECT_LOADING_DIALOG",
  CLOSE_CHECKOUT_REDIRECT_LOADING_DIALOG = "COMMERCE.CLOSE_CHECKOUT_REDIRECT_LOADING_DIALOG",

  START_BATCH_COUPON = "COMMERCE.START_BATCH_COUPON",
  SUCCEED_BATCH_COUPON = "COMMERCE.SUCCEED_BATCH_COUPON",
  FAILED_BATCH_COUPON = "COMMERCE.FAILED_BATCH_COUPON",

  START_BATCH_PRODUCT = "COMMERCE.START_BATCH_PRODUCT",
  SUCCEED_BATCH_PRODUCT = "COMMERCE.SUCCEED_BATCH_PRODUCT",
  FAILED_BATCH_PRODUCT = "COMMERCE.FAILED_BATCH_PRODUCT",

  START_BATCH_PRODUCT_SET = "COMMERCE.START_BATCH_PRODUCT_SET",
  SUCCEED_BATCH_PRODUCT_SET = "COMMERCE.SUCCEED_BATCH_PRODUCT_SET",
  FAILED_BATCH_PRODUCT_SET = "COMMERCE.FAILED_BATCH_PRODUCT_SET",

  START_BATCH_SELLER = "COMMERCE.START_BATCH_SELLER",
  SUCCEED_BATCH_SELLER = "COMMERCE.SUCCEED_BATCH_SELLER",
  FAILED_BATCH_SELLER = "COMMERCE.FAILED_BATCH_SELLER",

  START_FETCH_MY_ADMINISTRATED_SELLERS = "COMMERCE.START_FETCH_MY_ADMINISTRATED_SELLERS",
  SUCCEED_FETCH_MY_ADMINISTRATED_SELLERS = "COMMERCE.SUCCEED_FETCH_MY_ADMINISTRATED_SELLERS",
  FAILED_FETCH_MY_ADMINISTRATED_SELLERS = "COMMERCE.FAILED_FETCH_MY_ADMINISTRATED_SELLERS",

  START_FETCH_SELLER_DATA = "COMMERCE.START_FETCH_SELLER_DATA",
  SUCCEED_FETCH_SELLER_DATA = "COMMERCE.SUCCEED_FETCH_SELLER_DATA",
  FAILED_FETCH_SELLER_DATA = "COMMERCE.FAILED_FETCH_SELLER_DATA",

  START_FETCH_SELLER_CATEGORY = "COMMERCE.START_FETCH_SELLER_CATEGORY",
  SUCCEED_FETCH_SELLER_CATEGORY = "COMMERCE.SUCCEED_FETCH_SELLER_CATEGORY",
  FAILED_FETCH_SELLER_CATEGORY = "COMMERCE.FAILED_FETCH_SELLER_CATEGORY",

  START_FETCH_SELLER_CATEGORIES = "COMMERCE.START_FETCH_SELLER_CATEGORIES",
  SUCCEED_FETCH_SELLER_CATEGORIES = "COMMERCE.SUCCEED_FETCH_SELLER_CATEGORIES",
  FAILED_FETCH_SELLER_CATEGORIES = "COMMERCE.FAILED_FETCH_SELLER_CATEGORIES",

  START_FETCH_SELLER_SUB_SELLERS = "COMMERCE.START_FETCH_SELLER_SUB_SELLERS",
  SUCCEED_FETCH_SELLER_SUB_SELLERS = "COMMERCE.SUCCEED_FETCH_SELLER_SUB_SELLERS",
  FAILED_FETCH_SELLER_SUB_SELLERS = "COMMERCE.FAILED_FETCH_SELLER_SUB_SELLERS",
  CLEAR_FETCH_SELLER_SUB_SELLERS = "COMMERCE.CLEAR_FETCH_SELLER_SUB_SELLERS",

  START_FETCH_SELLER_PRODUCTS = "COMMERCE.START_FETCH_SELLER_PRODUCTS",
  SUCCEED_FETCH_SELLER_PRODUCTS = "COMMERCE.SUCCEED_FETCH_SELLER_PRODUCTS",
  FAILED_FETCH_SELLER_PRODUCTS = "COMMERCE.FAILED_FETCH_SELLER_PRODUCTS",
  CLEAR_FETCH_SELLER_PRODUCTS = "COMMERCE.CLEAR_FETCH_SELLER_PRODUCTS",

  START_FETCH_SELLER_PRODUCT_SETS = "COMMERCE.START_FETCH_SELLER_PRODUCT_SETS",
  SUCCEED_FETCH_SELLER_PRODUCT_SETS = "COMMERCE.SUCCEED_FETCH_SELLER_PRODUCT_SETS",
  FAILED_FETCH_SELLER_PRODUCT_SETS = "COMMERCE.FAILED_FETCH_SELLER_PRODUCT_SETS",
  CLEAR_FETCH_SELLER_PRODUCT_SETS = "COMMERCE.CLEAR_FETCH_SELLER_PRODUCT_SETS",

  START_FETCH_BRAND_DATA = "COMMERCE.START_FETCH_BRAND_DATA",
  SUCCEED_FETCH_BRAND_DATA = "COMMERCE.SUCCEED_FETCH_BRAND_DATA",
  FAILED_FETCH_BRAND_DATA = "COMMERCE.FAILED_FETCH_BRAND_DATA",

  START_FETCH_SELLER_PRODUCT_DATA = "COMMERCE.START_FETCH_SELLER_PRODUCT_DATA",
  SUCCEED_FETCH_SELLER_PRODUCT_DATA = "COMMERCE.SUCCEED_FETCH_SELLER_PRODUCT_DATA",
  FAILED_FETCH_SELLER_PRODUCT_DATA = "COMMERCE.FAILED_FETCH_SELLER_PRODUCT_DATA",

  START_SEARCH_SELLER_PRODUCTS = "COMMERCE.START_SEARCH_SELLER_PRODUCTS",
  SUCCEED_SEARCH_SELLER_PRODUCTS = "COMMERCE.SUCCEED_SEARCH_SELLER_PRODUCTS",
  FAILED_SEARCH_SELLER_PRODUCTS = "COMMERCE.FAILED_SEARCH_SELLER_PRODUCTS",
  CLEAR_SEARCH_SELLER_PRODUCTS = "COMMERCE.CLEAR_SEARCH_SELLER_PRODUCTS",

  START_SEARCH_BRANDS_BY_QUERY = "COMMERCE.START_SEARCH_BRANDS_BY_QUERY",
  SUCCEED_SEARCH_BRANDS_BY_QUERY = "COMMERCE.SUCCEED_SEARCH_BRANDS_BY_QUERY",
  FAILED_SEARCH_BRANDS_BY_QUERY = "COMMERCE.FAILED_SEARCH_BRANDS_BY_QUERY",
  CLEAR_SEARCH_BRANDS_BY_QUERY = "COMMERCE.CLEAR_SEARCH_BRANDS_BY_QUERY",

  START_SEARCH_PRODUCTS_BY_QUERY = "COMMERCE.START_SEARCH_PRODUCTS_BY_QUERY",
  SUCCEED_SEARCH_PRODUCTS_BY_QUERY = "COMMERCE.SUCCEED_SEARCH_PRODUCTS_BY_QUERY",
  FAILED_SEARCH_PRODUCTS_BY_QUERY = "COMMERCE.FAILED_SEARCH_PRODUCTS_BY_QUERY",
  CLEAR_SEARCH_PRODUCTS_BY_QUERY = "COMMERCE.CLEAR_SEARCH_PRODUCTS_BY_QUERY",

  START_FETCH_PAYMENTS = "COMMERCE.START_FETCH_PAYMENTS",
  SUCCEED_FETCH_PAYMENTS = "COMMERCE.SUCCEED_FETCH_PAYMENTS",
  FAILED_FETCH_PAYMENTS = "COMMERCE.FAILED_FETCH_PAYMENTS",

  START_FETCH_PAYMENT = "COMMERCE.START_FETCH_PAYMENT",
  SUCCEED_FETCH_PAYMENT = "COMMERCE.SUCCEED_FETCH_PAYMENT",
  FAILED_FETCH_PAYMENT = "COMMERCE.FAILED_FETCH_PAYMENT",

  START_CANCEL_PAYMENT = "COMMERCE.START_CANCEL_PAYMENT",
  SUCCEED_CANCEL_PAYMENT = "COMMERCE.SUCCEED_CANCEL_PAYMENT",
  FAILED_CANCEL_PAYMENT = "COMMERCE.FAILED_CANCEL_PAYMENT",

  START_FETCH_REFUND = "COMMERCE.START_FETCH_REFUND",
  SUCCEED_FETCH_REFUND = "COMMERCE.SUCCEED_FETCH_REFUND",
  FAILED_FETCH_REFUND = "COMMERCE.FAILED_FETCH_REFUND",

  START_FETCH_PRODUCT_REVIEWS = "COMMERCE.START_FETCH_PRODUCT_REVIEWS",
  SUCCEED_FETCH_PRODUCT_REVIEWS = "COMMERCE.SUCCEED_FETCH_PRODUCT_REVIEWS",
  FAILED_FETCH_PRODUCT_REVIEWS = "COMMERCE.FAILED_FETCH_PRODUCT_REVIEWS",

  START_CREATE_PRODUCT_REVIEW = "COMMERCE.START_CREATE_PRODUCT_REVIEW",
  SUCCEED_CREATE_PRODUCT_REVIEW = "COMMERCE.SUCCEED_CREATE_PRODUCT_REVIEW",
  FAILED_CREATE_PRODUCT_REVIEW = "COMMERCE.FAILED_CREATE_PRODUCT_REVIEW",

  START_UPDATE_PRODUCT_REVIEW = "COMMERCE.START_UPDATE_PRODUCT_REVIEW",
  SUCCEED_UPDATE_PRODUCT_REVIEW = "COMMERCE.SUCCEED_UPDATE_PRODUCT_REVIEW",
  FAILED_UPDATE_PRODUCT_REVIEW = "COMMERCE.FAILED_UPDATE_PRODUCT_REVIEW",

  START_DELETE_PRODUCT_REVIEW = "COMMERCE.START_DELETE_PRODUCT_REVIEW",
  SUCCEED_DELETE_PRODUCT_REVIEW = "COMMERCE.SUCCEED_DELETE_PRODUCT_REVIEW",
  FAILED_DELETE_PRODUCT_REVIEW = "COMMERCE.FAILED_DELETE_PRODUCT_REVIEW",

  START_FETCH_PRODUCT_REVIEW_REPLIES = "COMMERCE.START_FETCH_PRODUCT_REVIEW_REPLIES",
  SUCCEED_FETCH_PRODUCT_REVIEW_REPLIES = "COMMERCE.SUCCEED_FETCH_PRODUCT_REVIEW_REPLIES",
  FAILED_FETCH_PRODUCT_REVIEW_REPLIES = "COMMERCE.FAILED_FETCH_PRODUCT_REVIEW_REPLIES",

  START_CREATE_PRODUCT_REVIEW_REPLY = "COMMERCE.START_CREATE_PRODUCT_REVIEW_REPLY",
  SUCCEED_CREATE_PRODUCT_REVIEW_REPLY = "COMMERCE.SUCCEED_CREATE_PRODUCT_REVIEW_REPLY",
  FAILED_CREATE_PRODUCT_REVIEW_REPLY = "COMMERCE.FAILED_CREATE_PRODUCT_REVIEW_REPLY",

  START_UPDATE_PRODUCT_REVIEW_REPLY = "COMMERCE.START_UPDATE_PRODUCT_REVIEW_REPLY",
  SUCCEED_UPDATE_PRODUCT_REVIEW_REPLY = "COMMERCE.SUCCEED_UPDATE_PRODUCT_REVIEW_REPLY",
  FAILED_UPDATE_PRODUCT_REVIEW_REPLY = "COMMERCE.FAILED_UPDATE_PRODUCT_REVIEW_REPLY",

  START_FETCH_PRODUCT_QUESTIONS = "COMMERCE.START_FETCH_PRODUCT_QUESTIONS",
  SUCCEED_FETCH_PRODUCT_QUESTIONS = "COMMERCE.SUCCEED_FETCH_PRODUCT_QUESTIONS",
  FAILED_FETCH_PRODUCT_QUESTIONS = "COMMERCE.FAILED_FETCH_PRODUCT_QUESTIONS",

  START_CREATE_PRODUCT_QUESTION = "COMMERCE.START_CREATE_PRODUCT_QUESTION",
  SUCCEED_CREATE_PRODUCT_QUESTION = "COMMERCE.SUCCEED_CREATE_PRODUCT_QUESTION",
  FAILED_CREATE_PRODUCT_QUESTION = "COMMERCE.FAILED_CREATE_PRODUCT_QUESTION",

  START_UPDATE_PRODUCT_QUESTION = "COMMERCE.START_UPDATE_PRODUCT_QUESTION",
  SUCCEED_UPDATE_PRODUCT_QUESTION = "COMMERCE.SUCCEED_UPDATE_PRODUCT_QUESTION",
  FAILED_UPDATE_PRODUCT_QUESTION = "COMMERCE.FAILED_UPDATE_PRODUCT_QUESTION",

  START_DELETE_PRODUCT_QUESTION = "COMMERCE.START_DELETE_PRODUCT_QUESTION",
  SUCCEED_DELETE_PRODUCT_QUESTION = "COMMERCE.SUCCEED_DELETE_PRODUCT_QUESTION",
  FAILED_DELETE_PRODUCT_QUESTION = "COMMERCE.FAILED_DELETE_PRODUCT_QUESTION",

  START_FETCH_PRODUCT_ANSWERS = "COMMERCE.START_FETCH_PRODUCT_ANSWERS",
  SUCCEED_FETCH_PRODUCT_ANSWERS = "COMMERCE.SUCCEED_FETCH_PRODUCT_ANSWERS",
  FAILED_FETCH_PRODUCT_ANSWERS = "COMMERCE.FAILED_FETCH_PRODUCT_ANSWERS",

  START_CREATE_PRODUCT_ANSWER = "COMMERCE.START_CREATE_PRODUCT_ANSWER",
  SUCCEED_CREATE_PRODUCT_ANSWER = "COMMERCE.SUCCEED_CREATE_PRODUCT_ANSWER",
  FAILED_CREATE_PRODUCT_ANSWER = "COMMERCE.FAILED_CREATE_PRODUCT_ANSWER",

  START_UPDATE_PRODUCT_ANSWER = "COMMERCE.START_UPDATE_PRODUCT_ANSWER",
  SUCCEED_UPDATE_PRODUCT_ANSWER = "COMMERCE.SUCCEED_UPDATE_PRODUCT_ANSWER",
  FAILED_UPDATE_PRODUCT_ANSWER = "COMMERCE.FAILED_UPDATE_PRODUCT_ANSWER",

  START_DELETE_PRODUCT_ANSWER = "COMMERCE.START_DELETE_PRODUCT_ANSWER",
  SUCCEED_DELETE_PRODUCT_ANSWER = "COMMERCE.SUCCEED_DELETE_PRODUCT_ANSWER",
  FAILED_DELETE_PRODUCT_ANSWER = "COMMERCE.FAILED_DELETE_PRODUCT_ANSWER",

  START_FETCH_FUND_COMMENTS = "COMMERCE.START_FETCH_FUND_COMMENTS",
  SUCCEED_FETCH_FUND_COMMENTS = "COMMERCE.SUCCEED_FETCH_FUND_COMMENTS",
  FAILED_FETCH_FUND_COMMENTS = "COMMERCE.FAILED_FETCH_FUND_COMMENTS",

  START_CREATE_FUND_COMMENT = "COMMERCE.START_CREATE_FUND_COMMENT",
  SUCCEED_CREATE_FUND_COMMENT = "COMMERCE.SUCCEED_CREATE_FUND_COMMENT",
  FAILED_CREATE_FUND_COMMENT = "COMMERCE.FAILED_CREATE_FUND_COMMENT",

  START_UPDATE_FUND_COMMENT = "COMMERCE.START_UPDATE_FUND_COMMENT",
  SUCCEED_UPDATE_FUND_COMMENT = "COMMERCE.SUCCEED_UPDATE_FUND_COMMENT",
  FAILED_UPDATE_FUND_COMMENT = "COMMERCE.FAILED_UPDATE_FUND_COMMENT",

  START_DELETE_FUND_COMMENT = "COMMERCE.START_DELETE_FUND_COMMENT",
  SUCCEED_DELETE_FUND_COMMENT = "COMMERCE.SUCCEED_DELETE_FUND_COMMENT",
  FAILED_DELETE_FUND_COMMENT = "COMMERCE.FAILED_DELETE_FUND_COMMENT",

  START_PUT_PRODUCT_VOTE = "COMMERCE.START_PUT_PRODUCT_VOTE",
  SUCCEED_PUT_PRODUCT_VOTE = "COMMERCE.SUCCEED_PUT_PRODUCT_VOTE",
  FAILED_PUT_PRODUCT_VOTE = "COMMERCE.FAILED_PUT_PRODUCT_VOTE",

  START_FETCH_CART = "COMMERCE.START_FETCH_CART",
  SUCCEED_FETCH_CART = "COMMERCE.SUCCEED_FETCH_CART",
  FAILED_FETCH_CART = "COMMERCE.FAILED_FETCH_CART",

  START_ADD_TO_CART = "COMMERCE.START_ADD_TO_CART",
  SUCCEED_ADD_TO_CART = "COMMERCE.SUCCEED_ADD_TO_CART",
  FAILED_ADD_TO_CART = "COMMERCE.FAILED_ADD_TO_CART",

  START_UPDATE_CART = "COMMERCE.START_UPDATE_CART",
  SUCCEED_UPDATE_CART = "COMMERCE.SUCCEED_UPDATE_CART",
  FAILED_UPDATE_CART = "COMMERCE.FAILED_UPDATE_CART",

  START_PAYMENT_CALC = "COMMERCE.START_PAYMENT_CALC",
  SUCCEED_PAYMENT_CALC = "COMMERCE.SUCCEED_PAYMENT_CALC",
  FAILED_PAYMENT_CALC = "COMMERCE.FAILED_PAYMENT_CALC",

  START_FETCH_MY_CREDIT_HISTORIES = "COMMERCE.START_FETCH_MY_CREDIT_HISTORIES",
  SUCCEED_FETCH_MY_CREDIT_HISTORIES = "COMMERCE.SUCCEED_FETCH_MY_CREDIT_HISTORIES",
  FAILED_FETCH_MY_CREDIT_HISTORIES = "COMMERCE.FAILED_FETCH_MY_CREDIT_HISTORIES",

  FETCH_TO_BE_EXPIRED_CREDITS = "COMMERCE.FETCH_TO_BE_EXPIRED_CREDITS",

  OPEN_DELIVERY_TRACKING_DIALOG = "COMMERCE.OPEN_DELIVERY_TRACKING_DIALOG",
  CLOSE_DELIVERY_TRACKING_DIALOG = "COMMERCE.CLOSE_DELIVERY_TRACKING_DIALOG",

  OPEN_REVIEW_WRITE_DIALOG = "COMMERCE.OPEN_REVIEW_WRITE_DIALOG",
  CLOSE_REVIEW_WRITE_DIALOG = "COMMERCE.CLOSE_REVIEW_WRITE_DIALOG",

  OPEN_CANCEL_PAYMENT_DIALOG = "COMMERCE.OPEN_CANCEL_PAYMENT_DIALOG",
  CLOSE_CANCEL_PAYMENT_DIALOG = "COMMERCE.CLOSE_CANCEL_PAYMENT_DIALOG",

  START_FETCH_MY_COUPONS = "COMMERCE.START_FETCH_MY_COUPONS",
  SUCCEED_FETCH_MY_COUPONS = "COMMERCE.SUCCEED_FETCH_MY_COUPONS",
  FAILED_FETCH_MY_COUPONS = "COMMERCE.FAILED_FETCH_MY_COUPONS",

  START_FETCH_MY_AVAILABLE_COUPONS = "COMMERCE.START_FETCH_MY_AVAILABLE_COUPONS",
  SUCCEED_FETCH_MY_AVAILABLE_COUPONS = "COMMERCE.SUCCEED_FETCH_MY_AVAILABLE_COUPONS",
  FAILED_FETCH_MY_AVAILABLE_COUPONS = "COMMERCE.FAILED_FETCH_MY_AVAILABLE_COUPONS",

  START_MANUAL_PURCHASE_ITEM_CONFIRM = "COMMERCE.START_MANUAL_PURCHASE_ITEM_CONFIRM",
  SUCCEED_MANUAL_PURCHASE_ITEM_CONFIRM = "COMMERCE.SUCCEED_MANUAL_PURCHASE_ITEM_CONFIRM",
  FAILED_MANUAL_PURCHASE_ITEM_CONFIRM = "COMMERCE.FAILED_MANUAL_PURCHASE_ITEM_CONFIRM",

  START_CREATE_SHIPPING_ADDRESS = "START_CREATE_SHIPPING_ADDRESS",
  SUCCEED_CREATE_SHIPPING_ADDRESS = "SUCCEED_CREATE_SHIPPING_ADDRESS",
  FAILED_CREATE_SHIPPING_ADDRESS = "FAILED_CREATE_SHIPPING_ADDRESS",

  START_UPDATE_SHIPPING_ADDRESS = "START_UPDATE_SHIPPING_ADDRESS",
  SUCCEED_UPDATE_SHIPPING_ADDRESS = "SUCCEED_UPDATE_SHIPPING_ADDRESS",
  FAILED_UPDATE_SHIPPING_ADDRESS = "FAILED_UPDATE_SHIPPING_ADDRESS",

  START_SET_AS_DEFAULT_SHIPPING_ADDRESS = "START_SET_AS_DEFAULT_SHIPPING_ADDRESS",
  SUCCEED_SET_AS_DEFAULT_SHIPPING_ADDRESS = "SUCCEED_SET_AS_DEFAULT_SHIPPING_ADDRESS",
  FAILED_SET_AS_DEFAULT_SHIPPING_ADDRESS = "FAILED_SET_AS_DEFAULT_SHIPPING_ADDRESS",

  START_DELETE_SHIPPING_ADDRESS = "START_DELETE_SHIPPING_ADDRESS",
  SUCCEED_DELETE_SHIPPING_ADDRESS = "SUCCEED_DELETE_SHIPPING_ADDRESS",
  FAILED_DELETE_SHIPPING_ADDRESS = "FAILED_DELETE_SHIPPING_ADDRESS",

  START_FETCH_SHIPPING_ADDRESS_LIST = "START_FETCH_SHIPPING_ADDRESS_LIST",
  SUCCEED_FETCH_SHIPPING_ADDRESS_LIST = "SUCCEED_FETCH_SHIPPING_ADDRESS_LIST",
  FAILED_FETCH_SHIPPING_ADDRESS_LIST = "FAILED_FETCH_SHIPPING_ADDRESS_LIST",

  START_FETCH_FUND_PURCHASE_ITEM_LIST = "START_FETCH_FUND_PURCHASE_ITEM_LIST",
  SUCCEED_FETCH_FUND_PURCHASE_ITEM_LIST = "SUCCEED_FETCH_FUND_PURCHASE_ITEM_LIST",
  FAILED_FETCH_FUND_PURCHASE_ITEM_LIST = "FAILED_FETCH_FUND_PURCHASE_ITEM_LIST",

  SUCCEED_FETCH_PURCHASED_PRODUCT_LIST = "COMMERCE.SUCCEED_FETCH_PURCHASED_PRODUCT_LIST",

  START_DOWNLOAD_COUPON = "COMMERCE.START_DOWNLOAD_COUPON",
  SUCCEED_DOWNLOAD_COUPON = "COMMERCE.SUCCEED_DOWNLOAD_COUPON",
  FAILED_DOWNLOAD_COUPON = "COMMERCE.FAILED_DOWNLOAD_COUPON",

  START_SEARCH_DOWNLOAD_COUPONS = "COMMERCE.START_SEARCH_DOWNLOAD_COUPONS",
  SUCCEED_SEARCH_DOWNLOAD_COUPONS = "COMMERCE.SUCCEED_SEARCH_DOWNLOAD_COUPONS",
  FAILED_SEARCH_DOWNLOAD_COUPONS = "COMMERCE.FAILED_SEARCH_DOWNLOAD_COUPONS",

  START_FETCH_TEAMS_OF_PRODUCT = "COMMERCE.TEAM_BUYING.START_FETCH_TEAMS_OF_PRODUCT",
  SUCCEED_FETCH_TEAMS_OF_PRODUCT = "COMMERCE.TEAM_BUYING.SUCCEED_FETCH_TEAMS_OF_PRODUCT",
  FAILED_FETCH_TEAMS_OF_PRODUCT = "COMMERCE.TEAM_BUYING.FAILED_FETCH_TEAMS_OF_PRODUCT",

  START_FETCH_TEAMS_OF_TEAM_BUYING = "COMMERCE.TEAM_BUYING.START_FETCH_TEAMS_OF_TEAM_BUYING",
  SUCCEED_FETCH_TEAMS_OF_TEAM_BUYING = "COMMERCE.TEAM_BUYING.SUCCEED_FETCH_TEAMS_OF_TEAM_BUYING",
  FAILED_FETCH_TEAMS_OF_TEAM_BUYING = "COMMERCE.TEAM_BUYING.FAILED_FETCH_TEAMS_OF_TEAM_BUYING",

  START_CREATE_DRAFT_TEAM = "COMMERCE.TEAM_BUYING.START_CREATE_DRAFT_TEAM",
  SUCCEED_CREATE_DRAFT_TEAM = "COMMERCE.TEAM_BUYING.SUCCEED_CREATE_DRAFT_TEAM",
  FAILED_CREATE_DRAFT_TEAM = "COMMERCE.TEAM_BUYING.FAILED_CREATE_DRAFT_TEAM",

  START_GET_ALL_TEAM_COUNT = "COMMERCE.TEAM_BUYING.START_GET_ALL_TEAM_COUNT",
  SUCCEED_GET_ALL_TEAM_COUNT = "COMMERCE.TEAM_BUYING.SUCCEED_GET_ALL_TEAM_COUNT",
  FAILED_GET_ALL_TEAM_COUNT = "COMMERCE.TEAM_BUYING.FAILED_GET_ALL_TEAM_COUNT",
}

export enum CampaignProjectTypes {
  START_GET_ALL_CAMPAIGNS = "CAMPAIGN_PROJECT.START_GET_ALL_CAMPAIGNS",
  SUCCEED_GET_ALL_CAMPAIGNS = "CAMPAIGN_PROJECT.SUCCEED_GET_ALL_CAMPAIGNS",
  FAILED_GET_ALL_CAMPAIGNS = "CAMPAIGN_PROJECT.FAILED_GET_ALL_CAMPAIGNS",

  START_FETCH_CAMPAIGN = "CAMPAIGN_PROJECT.START_FETCH_CAMPAIGN",
  SUCCEED_FETCH_CAMPAIGN = "CAMPAIGN_PROJECT.SUCCEED_FETCH_CAMPAIGN",
  FAILED_FETCH_CAMPAIGN = "CAMPAIGN_PROJECT.FAILED_FETCH_CAMPAIGN",

  START_FETCH_EXECUTION = "CAMPAIGN_PROJECT.START_START_FETCH_EXECUTION",
  SUCCEED_FETCH_EXECUTION = "CAMPAIGN_PROJECT.SUCCEED_SUCCEED_FETCH_EXECUTION",
  FAILED_FETCH_EXECUTION = "CAMPAIGN_PROJECT.FAILED_FAILED_FETCH_EXECUTION",

  START_CREATE_EXECUTION = "CAMPAIGN_PROJECT.START_CREATE_EXECUTION",
  SUCCEED_CREATE_EXECUTION = "CAMPAIGN_PROJECT.SUCCEED_CREATE_EXECUTION",
  FAILED_CREATE_EXECUTION = "CAMPAIGN_PROJECT.FAILED_CREATE_EXECUTION",

  START_VOTE_EXECUTION = "CAMPAIGN_PROJECT.START_VOTE_EXECUTION",
  SUCCEED_VOTE_EXECUTION = "CAMPAIGN_PROJECT.SUCCEED_VOTE_EXECUTION",
  FAILED_VOTE_EXECUTION = "CAMPAIGN_PROJECT.FAILED_VOTE_EXECUTION",

  START_GET_EXECUTION_VOTES = "CAMPAIGN_PROJECT.START_GET_EXECUTION_VOTES",
  SUCCEED_GET_EXECUTION_VOTES = "CAMPAIGN_PROJECT.SUCCEED_GET_EXECUTION_VOTES",
  FAILED_GET_EXECUTION_VOTES = "CAMPAIGN_PROJECT.FAILED_GET_EXECUTION_VOTES",

  OPEN_EXECUTION_CREATE_DIALOG = "CAMPAIGN_PROJECT.OPEN_EXECUTION_CREATE_DIALOG",
  CLOSE_EXECUTION_CREATE_DIALOG = "CAMPAIGN_PROJECT.CLOSE_EXECUTION_CREATE_DIALOG",

  OPEN_EXECUTION_VOTE_DIALOG = "CAMPAIGN_PROJECT.OPEN_EXECUTION_VOTE_DIALOG",
  CLOSE_EXECUTION_VOTE_DIALOG = "CAMPAIGN_PROJECT.CLOSE_EXECUTION_VOTE_DIALOG",

  OPEN_EXECUTION_VOTE_LIST_DIALOG = "CAMPAIGN_PROJECT.OPEN_EXECUTION_VOTE_LIST_DIALOG",
  CLOSE_EXECUTION_VOTE_LIST_DIALOG = "CAMPAIGN_PROJECT.CLOSE_EXECUTION_VOTE_LIST_DIALOG",

  OPEN_EXECUTION_VIEW_DIALOG = "CAMPAIGN_PROJECT.OPEN_EXECUTION_VIEW_DIALOG",
  CLOSE_EXECUTION_VIEW_DIALOG = "CAMPAIGN_PROJECT.CLOSE_EXECUTION_VIEW_DIALOG",

  OPEN_REMIT_RESULT_DIALOG = "CAMPAIGN_PROJECT.OPEN_REMIT_RESULT_DIALOG",
  CLOSE_REMIT_RESULT_DIALOG = "CAMPAIGN_PROJECT.CLOSE_REMIT_RESULT_DIALOG",

  START_REMIT_EXECUTION_FUND = "CAMPAIGN.START_REMIT_EXECUTION_FUND",
  SUCCEED_REMIT_EXECUTION_FUND = "CAMPAIGN.SUCCEED_REMIT_EXECUTION_FUND",
  FAILED_REMIT_EXECUTION_FUND = "CAMPAIGN.FAILED_REMIT_EXECUTION_FUND",

  CLEAR_EXECUTION_ENTITY = "CAMPAIGN_PROJECT.CLEAR_EXECUTION_ENTITY",
}

export enum ThreadShowModalTypes {
  OPEN_THREAD_SHOW_MODAL = "THREAD_SHOW_MODAL.OPEN_THREAD_SHOW_MODAL",
  CLOSE_THREAD_SHOW_MODAL = "THREAD_SHOW_MODAL.CLOSE_THREAD_SHOW_MODAL",
}

export enum PromoteTypes {
  SET_APP_DOWNLOAD_PROMOTIONS = "PROMOTE.SET_APP_DOWNLOAD_PROMOTIONS",
  SET_POPUP_BANNER = "PROMOTE.SET_POPUP_BANNER",
}

export enum NftTypes {
  START_FETCHING_TOKEN_LIST = "TOKEN.START_FETCHING_TOKEN_LIST",
  SUCCEEDED_FETCHING_TOKEN_LIST = "TOKEN.SUCCEEDED_FETCHING_TOKEN_LIST",
  FAILED_FETCHING_TOKEN_LIST = "TOKEN.FAILED_FETCHING_TOKEN_LIST",

  START_FETCHING_NFTSET_LIST = "NFT.START_FETCHING_NFTSET_LIST",
  SUCCEEDED_FETCHING_NFTSET_LIST = "NFT.SUCCEEDED_FETCHING_NFTSET_LIST",
  FAILED_FETCHING_NFTSET_LIST = "NFT.FAILED_FETCHING_NFTSET_LIST",

  OPEN_MINT_REDIRECT_LOADING_DIALOG = "NFT.OPEN_MINT_REDIRECT_LOADING_DIALOG",
  CLOSE_MINT_REDIRECT_LOADING_DIALOG = "NFT.CLOSE_MINT_REDIRECT_LOADING_DIALOG",
}

export enum CommunityCoinTypes {
  SUCCEED_GET_TO_BE_EXPIRED_COINS = "COMMUNITY_COIN.SUCCEED_GET_TO_BE_EXPIRED_COINS",
}

export enum DQuestTypes {
  START_FETCH_QUEST_LIST = "DQUEST.START_FETCH_QUEST_LIST",
  SUCCEED_FETCH_QUEST_LIST = "DQUEST.SUCCEED_FETCH_QUEST_LIST",
  FAILED_FETCH_QUEST_LIST = "DQUEST.FAILED_FETCH_QUEST_LIST",

  START_FETCH_QUEST = "DQUEST.START_FETCH_QUEST",
  SUCCEED_FETCH_QUEST = "DQUEST.SUCCEED_FETCH_QUEST",
  FAILED_FETCH_QUEST = "DQUEST.FAILED_FETCH_QUEST",

  START_FETCH_HISTORY = "DQUEST.START_FETCH_HISTORY",
  SUCCEED_FETCH_HISTORY = "DQUEST.SUCCEED_FETCH_HISTORY",
  FAILED_FETCH_HISTORY = "DQUEST.FAILED_FETCH_HISTORY",

  START_FETCH_HISTORIES = "DQUEST.START_FETCH_HISTORIES",
  SUCCEED_FETCH_HISTORIES = "DQUEST.SUCCEED_FETCH_HISTORIES",
  FAILED_FETCH_HISTORIES = "DQUEST.FAILED_FETCH_HISTORIES",

  START_FETCH_QUEST_ACTION = "DQUEST.START_FETCH_QUEST_ACTION",
  SUCCEED_FETCH_QUEST_ACTION = "DQUEST.SUCCEED_FETCH_QUEST_ACTION",
  FAILED_FETCH_QUEST_ACTION = "DQUEST.FAILED_FETCH_QUEST_ACTION",

  START_FETCH_QUEST_GROUP_ACTION = "DQUEST.START_FETCH_QUEST_GROUP_ACTION",
  SUCCEED_FETCH_QUEST_GROUP_ACTION = "DQUEST.SUCCEED_FETCH_QUEST_GROUP_ACTION",
  FAILED_FETCH_QUEST_GROUP_ACTION = "DQUEST.FAILED_FETCH_QUEST_GROUP_ACTION",

  START_FETCH_QUEST_GROUP_QUESTS_ACTION = "DQUEST.START_FETCH_QUEST_GROUP_QUESTS_ACTION",
  SUCCEED_FETCH_QUEST_GROUP_QUESTS_ACTION = "DQUEST.SUCCEED_FETCH_QUEST_GROUP_QUESTS_ACTION",
  FAILED_FETCH_QUEST_GROUP_QUESTS_ACTION = "DQUEST.FAILED_FETCH_QUEST_GROUP_QUESTS_ACTION",
}

export enum DQuestCompleteDialogTypes {
  OPEN_DQUEST_COMPLETE_DIALOG = "DQUEST.OPEN_DQUEST_COMPLETE_DIALOG",
  CLOSE_DQUEST_COMPLETE_DIALOG = "DQUEST.CLOSE_DQUEST_COMPLETE_DIALOG",
}

export enum PopupBannerTypes {
  OPEN = "POPUP_BANNER.OPEN",
  CLOSE = "POPUP_BANNER.CLOSE",
  CLEAR = "POPUP_BANNER.CLEAR",
}

export enum GlobalAlertDialogTypes {
  OPEN = "GlobalAlertDialogTypes.OPEN",
  CLOSE = "GlobalAlertDialogTypes.CLOSE",
}

export enum GlobalQuickJointDialogTypes {
  OPEN = "GlobalQuickJointDialogTypes.OPEN",
  CLOSE = "GlobalQuickJointDialogTypes.CLOSE",
}

export enum ComponentLayoutTypes {
  UPDATE_COMPONENT_LAYOUT = "COMPONENT_LAYOUT.UPDATE_COMPONENT_LAYOUT",
}

export enum GlobalUserStatusFeedbackDialog {
  OPEN = "GlobalUserFeedbackDialog.OPEN",
  CLOSE = "GlobalUserFeedbackDialog.CLOSE",
}

export enum GlobalDuplicatePostDialogTypes {
  OPEN = "GlobalDuplicatePostDialogTypes.OPEN",
  CLOSE = "GlobalDuplicatePostDialogTypes.CLOSE",
}

export enum GlobalMovePostDialogTypes {
  OPEN = "GlobalMovePostDialogTypes.OPEN",
  CLOSE = "GlobalMovePostDialogTypes.CLOSE",
}

export enum StatTypes {
  NEW_MESSAGE_ARRIVED = "StatTypes.NEW_MESSAGE_ARRIVED",
}

export enum GlobalFeedEditorDialogTypes {
  OPEN = "GlobalFeedEditorDialogTypes.OPEN",
  CLOSE = "GlobalFeedEditorDialogTypes.CLOSE",
}
