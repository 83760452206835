import useEntitiesFetchSelector from "./useEntitiesFetchSelector";

const EMPTY_ARRAY = [];

export default function useChannelFetchSelector(requestIds: Moim.Id[]) {
  const { isLoading, entities } = useEntitiesFetchSelector({
    channels: requestIds,
  });

  return {
    isLoading,
    channels: entities.channels ?? EMPTY_ARRAY,
  };
}
