import uuid from "uuid";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";
import { TextGeneralButton } from "common/components/designSystem/buttons";
import { MoimURL } from "common/helpers/url";
import { useIntlShort } from "common/hooks/useIntlShort";
import useRedirect from "common/hooks/useRedirect";

interface IQueue {
  id: Moim.Id;
  status: "pending" | "uploading" | "uploaded" | "failed" | "canceled";
  mediaUploadQueue: any[];
  threadPayload: any; // thread posting API payload

  mediaUploadTask?: Promise<any>[];
  threadTask?: Promise<{ threadId: Moim.Id; forumId?: Moim.Id }>;
}

interface IContext {
  queue: IQueue[];
  addTask: (threadId: Moim.Id, forumId?: Moim.Id) => Moim.Id;
  cancelTask: (id: Moim.Id) => void;
  cancelAllTasks: () => void;
}

export const ThreadUploadQueueContext = React.createContext<IContext>({
  queue: [],
  addTask: () => "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cancelTask: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cancelAllTasks: () => {},
});

export function useThreadUploadQueue() {
  const provider = React.useContext(ThreadUploadQueueContext);

  return provider;
}

const GoToThreadButton = ({
  threadId,
  forumId,
}: {
  threadId: Moim.Id;
  forumId?: Moim.Id;
}) => {
  const redirect = useRedirect();

  const handleClick = React.useCallback(() => {
    redirect(
      forumId
        ? new MoimURL.ShowForumThread({ forumId, threadId }).toString()
        : new MoimURL.ShowThread({ threadId }).toString(),
    );
  }, [forumId, redirect, threadId]);

  return (
    <TextGeneralButton size="s" onClick={handleClick}>
      <FormattedMessage id="button_go_to_post" />
    </TextGeneralButton>
  );
};

export function ThreadUploadQueueProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const intl = useIntlShort();
  const [queue, setQueue] = React.useState<IQueue[]>([]);

  const { open: successOpen } = useSnackbar({
    timeout: 5000,
    type: "float-flag",
    subType: "success",
    title: intl("floating_flag_post_published_title"),
  });

  const updateTask = React.useCallback((id: Moim.Id) => {
    setQueue(q =>
      q.map(task => {
        if (task.id === id) {
          task.threadTask?.then(({ threadId, forumId }) => {
            successOpen({
              rightIcon: {
                renderComponent: () => (
                  <GoToThreadButton threadId={threadId} forumId={forumId} />
                ),
              },
            });
          });

          return {
            ...task,
            status: "uploaded",
          };
        }
        return task;
      }),
    );
  }, []);

  const addTask = React.useCallback(
    // NOTE: threadId is temporary, will be removed
    (threadId: Moim.Id, forumId?: Moim.Id) => {
      const id = uuid.v4();

      setQueue(st => [
        ...st,
        {
          id,
          status: "pending",
          mediaUploadQueue: [],
          threadPayload: {},
          threadTask: new Promise(resolve => {
            setTimeout(() => {
              updateTask(id);
              resolve({ threadId, forumId });
            }, 1000);
          }),
        },
      ]);
      return id;
    },
    [updateTask],
  );

  const cancelTask = React.useCallback(
    (id: Moim.Id) => {
      setQueue(queue.filter(task => task.id !== id));
    },
    [queue],
  );

  const cancelAllTasks = React.useCallback(() => {
    setQueue([]);
  }, []);

  return (
    <ThreadUploadQueueContext.Provider
      value={{
        queue,
        addTask,
        cancelTask,
        cancelAllTasks,
      }}
    >
      {children}
    </ThreadUploadQueueContext.Provider>
  );
}

export interface IHoCProps {
  threadUploadQueue: IContext;
}

export function withThreadUploadQueue<T extends IHoCProps = IHoCProps>(
  WrappedComponent: React.ComponentType<T>,
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithQueue = (props: Omit<T, keyof IHoCProps>) => {
    const ctx = useThreadUploadQueue();
    return <WrappedComponent {...(props as T)} threadUploadQueue={ctx} />;
  };

  ComponentWithQueue.displayName = `withThreadUploadQueue(${displayName})`;
  return ComponentWithQueue;
}
