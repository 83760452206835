import { ActionCreators } from "app/common/components/dialogs/feedTypeEditorDialog/actions";
import { useActions, useStoreState } from "app/store";
import React from "react";
import { MoimURL } from "common/helpers/url";
import useRedirect from "common/hooks/useRedirect";
import useIsMobile from "./useIsMobile";

export function useBlockitEditorRedirectUrl() {
  const isMobile = useIsMobile();

  const isFeedTypeEditorOpen = useStoreState(
    state => state.feedTypeEditorDialog.open,
  );
  const channelEntities = useStoreState(state => state.entities.channels);
  const { openFeedEditorDialog } = useActions({
    openFeedEditorDialog: ActionCreators.open,
  });

  return React.useCallback(
    (payload: {
      channelId?: Moim.Id;
      threadId?: Moim.Id;
      draftId?: Moim.Id;
      afterSave?: string;
    }) => {
      if (payload.draftId) {
        if (isFeedTypeEditorOpen) {
          openFeedEditorDialog({
            fromDraftId: payload.draftId,
          });

          // const url = new URL(location.toString());
          // url.searchParams.set("draft", payload.draftId);
          // location.replace(url);
          return;
        } else {
          return new MoimURL.BlockitEditor().toString(undefined, {
            draft: payload.draftId,
          } as any);
        }
      }

      const channelEntity = payload.channelId
        ? (channelEntities[
            payload.channelId
          ] as Moim.Channel.IForumSimpleChannel)
        : undefined;

      if (!channelEntity) {
        return new MoimURL.BlockitEditor().toString(undefined, {
          draft: payload.draftId,
        } as any);
      }

      if (channelEntity.editorConfig?.type === "feed" && !isMobile) {
        openFeedEditorDialog({
          fromChannelId: payload.channelId,
          fromThreadId: payload.threadId,
          fromDraftId: payload.draftId,
          afterSave: payload.afterSave,
        });
        return null;
      } else {
        return new MoimURL.BlockitEditor().toString(undefined, {
          channel: payload.channelId,
          thread: payload.threadId,
          draft: payload.draftId,
          afterSave: payload.afterSave,
        } as any);
      }
    },
    [channelEntities, isMobile, isFeedTypeEditorOpen, openFeedEditorDialog],
  );
}

export default function useBlockitEditorRedirect() {
  const redirect = useRedirect();
  const isMobile = useIsMobile();

  const isFeedTypeEditorOpen = useStoreState(
    state => state.feedTypeEditorDialog.open,
  );
  const channelEntities = useStoreState(state => state.entities.channels);
  const { openFeedEditorDialog } = useActions({
    openFeedEditorDialog: ActionCreators.open,
  });

  return React.useCallback(
    (payload: {
      channelId?: Moim.Id;
      threadId?: Moim.Id;
      draftId?: Moim.Id;
      afterSave?: string;
    }) => {
      if (payload.draftId) {
        if (isFeedTypeEditorOpen) {
          openFeedEditorDialog({
            fromDraftId: payload.draftId,
          });

          // const url = new URL(location.toString());
          // url.searchParams.set("draft", payload.draftId);
          // location.replace(url);

          return;
        } else {
          redirect(
            new MoimURL.BlockitEditor().toString(undefined, {
              draft: payload.draftId,
            } as any),
          );
          return;
        }
      }

      const channelEntity = payload.channelId
        ? (channelEntities[
            payload.channelId
          ] as Moim.Channel.IForumSimpleChannel)
        : undefined;

      if (!channelEntity) {
        redirect(
          new MoimURL.BlockitEditor().toString(undefined, {
            draft: payload.draftId,
          } as any),
        );
        return;
      }

      if (channelEntity.editorConfig?.type === "feed" && !isMobile) {
        openFeedEditorDialog({
          fromChannelId: payload.channelId,
          fromThreadId: payload.threadId,
          fromDraftId: payload.draftId,
          afterSave: payload.afterSave,
        });
      } else {
        redirect(
          new MoimURL.BlockitEditor().toString(undefined, {
            channel: payload.channelId,
            thread: payload.threadId,
            draft: payload.draftId,
            afterSave: payload.afterSave,
          } as any),
        );
        return;
      }
    },
    [
      channelEntities,
      isMobile,
      isFeedTypeEditorOpen,
      openFeedEditorDialog,
      redirect,
    ],
  );
}
